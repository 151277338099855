import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from "react-router-dom";
import { useAuth } from '../../contexts/auth';
import './newsletterDetails.scss';
import TextBox from 'devextreme-react/text-box';
import TextArea from 'devextreme-react/text-area';
import { Button } from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import notify from 'devextreme/ui/notify';
import FileUploader from 'devextreme-react/file-uploader';
import {uploadHtml, uploadJson, getJsonEmailContent } from '../../api/newsletter';
import EmailEditor from 'react-email-editor';

export default function NewsletterDetails() {
   const location = useLocation();
    const urlOid = location.state.Oid;
    const week = location.state.Week;
    const fileName = location.state.FileName;
    const companyName = location.state.CompanyName;
    const [columnData, setColumnData] = useState([])
    const { user, signOut } = useAuth();
    const fileUploadURL = `${process.env.REACT_APP_URL}/api/EmailNewsletter/UploadImage`;
    const emailEditorRef = useRef(null);
    let fileUploaderRef = React.createRef();
    const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`
    }


    useEffect(() => {

    }, [])


    const onLoad = () => {
        const unlayer = emailEditorRef.current?.editor;
        getJsonEmailContent(week, fileName, companyName).then(res => {
            unlayer.loadDesign(JSON.parse(res.data));
        })
            .catch(error => {
                notify(error.message, 'error', 2000)
            })
    };

    const exportHtml = () => {
        const unlayer = emailEditorRef.current?.editor;

        unlayer?.exportHtml((data) => {
            const { design, html } = data;
            var myWindow = window.open("", "response", "resizable=yes");
            myWindow.document.write(html);
        });
    };

    const saveDesign = () => {
        const unlayer = emailEditorRef.current?.editor;
        unlayer.saveDesign(design => {
            uploadJson(week, fileName, companyName,design).then((result) => {
                if (result.status === 201) {
                    notify("Formulier is gewijzigd", 'success', 2000);
                }
            }).catch(error => notify(error.message, 'error', 2000));
        })

        unlayer?.exportHtml((data) => {
            const { design, html } = data;
            uploadHtml(week, fileName, companyName,html).then((result) => {
                if (result.status === 201) {
                    notify("Formulier is gewijzigd", 'success', 2000);
                }
            }).catch(error => notify(error.message, 'error', 2000));


        });
    }

  return (
    <React.Fragment>
          <h2 className={'content-block header'}>Newsletter week {week}</h2>
          <div className={'content-block'}>
              <Button
                  width={120}
                  text="Save"
                  type="success"
                  stylingMode="contained"
                  onClick={saveDesign}
              />
              <Button
                  width={120}
                  text="Example"
                  type="success"
                  stylingMode="contained"
                  onClick={exportHtml}
              />

          </div>
          <div className={'content-block'}>
              <EmailEditor ref={emailEditorRef} onLoad={onLoad} minHeight={1200} />
          </div>

    </React.Fragment>
  );
}
