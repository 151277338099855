import React, { useState, useEffect, useCallback } from 'react';
import Form, {
    SimpleItem,
    ButtonItem,
    ButtonOptions,
    StringLengthRule,
    Label,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { saveNewsletterCampaignFormRequest } from '../../api/newsletter';
export default function NewsletterCampaignForm({ onComplete }) {
    const [newsletterCampaignFormData, setNewsletterCampaignFormData] = useState({});

    useEffect(() => {
    }, []);

    const onSubmit = async (e)  => {
        e.preventDefault();
        await saveNewsletterCampaignFormRequest(newsletterCampaignFormData).then((result) => {
                if (result.status === 201) {
                    setNewsletterCampaignFormData({});
                    onComplete();
                    notify("Formulier is opgeslagen", 'success', 2000);
                }

            }).catch(error => notify(error.message, 'error', 2000));
    };
    const formDataChanged = (e) => {
        setNewsletterCampaignFormData({ ...newsletterCampaignFormData, [e.dataField]: e.value });
    }

    return (
        <React.Fragment>
            <div className={'content-block'}>
                <form onSubmit={onSubmit}>
                    <Form formData={newsletterCampaignFormData} onFieldDataChanged={formDataChanged} labelLocation="top" >
                    <SimpleItem editorType="dxTextBox" dataField="Name" >
                            <Label text="Newsletter Campaign name" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                    </SimpleItem>
                    <ButtonItem>
                        <ButtonOptions
                            width={'20%'}
                            type={'default'}
                            useSubmitBehavior={true}>
                            <span className="dx-button-text">
                                Save
                            </span>
                        </ButtonOptions>
                        </ButtonItem>
                    </Form>
                </form>
            </div>
         
        </React.Fragment>
    );
}
