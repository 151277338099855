
import React, { useCallback } from 'react';
import './recipes.scss';
import 'devextreme/data/odata/store';
import { useAuth } from '../../contexts/auth';
import { days } from '../../Enums';
import DataGrid, {
    Button,
    Column,
    Pager,
    Paging,
    FilterRow,
    Editing,
    SearchPanel,
    Popup,
    Lookup
} from 'devextreme-react/data-grid';
import { Item, RequiredRule } from 'devextreme-react/form';
import FileUploader from 'devextreme-react/file-uploader';
import notify from 'devextreme/ui/notify';
import { getRecipeDocument } from '../../api/recipe';
import { formatMessage } from 'devextreme/localization';
export default function Recipes() {
    const { user, signOut } = useAuth();
    const fileUploadURL = `${process.env.REACT_APP_URL}/api/RecepiePdf/UploadPDF`;
    let fileUploaderRef = React.createRef();
    const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`
    }

    function editCellRender(cellInfo) {
        return (
            <>
                <FileUploader ref={fileUploaderRef} uploadHeaders={headers}
                    name="file"
                    maxFileSize={1024 * 1024}
                    allowedFileExtensions={['.pdf']}
                    multiple={false} uploadMode="instantly"
                    uploadUrl={fileUploadURL}
                    onUploaded={e => onUploaded(e, cellInfo)} onUploadError={onUploadError} />
            </>
        );
    }
    const onUploaded = useCallback((e, cellInfo) => { 
        if (e.request.status === 201)
        {
            fileNameRecipe = e.request.responseText;
        }
    }, []);
    const onUploadError = useCallback(e => {
        let xhttp = e.request;
        if (xhttp.status === 400) {
            e.message = e.error.responseText;
        }
        if (xhttp.readyState === 4 && xhttp.status === 0) {
            e.message = "Connection refused";
        }
 
    }, []);
    const numberBoxOptions = {
        min: 0, max: 52, showSpinButtons: true, showClearButton: true,
    };

    const onDownloadPDFClick = useCallback((e) => {
        getRecipeDocument(e.row.data.Oid._value).then((res) => {
            const file = new Blob(
                [res.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            var fileName = res.headers['content-disposition'].replace("inline; filename=", "");
            fileLink.download = fileName;
            //fileLink.click();
            window.open(fileLink.href);
        }).catch((e) => {
            notify(e.message, 'error', 2000);
        });
        }, []);

    const dataSource = {
        store: {
            type: 'odata',
            version: 4,
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/Recipe`,
            errorHandler: (error) => {
                if (error.httpStatus === 401) {
                    signOut(true);
                }
            },
            beforeSend: function (e) {
                e.headers = {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                };

            },
            onInserting: function (values, key) {
                if (values.NutritionCategory) {
                    values["NutritionCategoryOid"] = values.NutritionCategory.Oid.toString();
                    delete values.NutritionCategory;
                }
                values["FileName"] = fileNameRecipe;
                values["FileName2"] = fileNameRecipe;
                fileNameRecipe = '';
            },
            onUpdating: function (key, values) {
                if (values.NutritionCategory) {
                    values["NutritionCategoryOid"] = values.NutritionCategory.Oid.toString();

                }

            }
        },
        select: [
            'Oid',
            'Name',
            'WeekNr',
            'DayOfWeek',
            'NutritionCategory',
            'NutritionCategoryOid',
            'FileName',
            'FileName2'

        ],
        expand: ['NutritionCategory'],
    };
    return (
        <React.Fragment>
            <h2 className={'content-block'}>{formatMessage('Recipes')}</h2>
            <DataGrid
                className={'dx-card wide-card'}
                dataSource={dataSource}
                showBorders={false}
                focusedRowEnabled={true}
                defaultFocusedRowIndex={0}
                columnAutoWidth={true}
                columnHidingEnabled={true}>
                <Editing
                    mode="popup"
                    allowDeleting={true}
                    allowAdding={true}
                    allowUpdating={true}>
                    <Popup title={formatMessage('AddRecipe')} width={766.4} height={750} showTitle={true} >
                        <Item itemType="group">
                            <Item dataField={'WeekNr'}/>
                            <Item dataField={'Name'} />
                        </Item>
                        <Item dataField="NutritionCategory.Oid" />
                        <Item itemType="group" caption="Pdf" >
                            {/*<Item dataField={'PdfFile'} />*/}
                        </Item>
                    </Popup>
                </Editing>
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />
                <SearchPanel width={500} visible={true} />
                <Column
                    width={150}
                    dataField={'WeekNr'}
                    defaultSortOrder="asc"
                    editorType="dxNumberBox"
                    editorOptions={numberBoxOptions}
                    caption={formatMessage('WeekNumber')} >
                    <RequiredRule message={formatMessage('WeekNrRequired')} />
                </Column>
                <Column
                    dataField={'Name'}
                    caption={formatMessage('FullName')}>
                    <RequiredRule message={formatMessage('NameRequired')} />  
                </Column>
                <Column
                    width={200}
                    dataField={'DayOfWeek'}
                    caption={formatMessage('Day')}>
                    <Lookup
                        dataSource={days}
                        valueExpr={'Value'}
                        displayExpr={'Name'} />
                    <RequiredRule message={formatMessage('DayOfWeekRequired')} />    
                </Column>
                <Column
                    dataField={'NutritionCategory.Oid'}
                    caption={formatMessage('NutritionCategory')}>
                    <Lookup
                        dataSource={nutritionCategoryData}
                        valueExpr={'Oid'}
                        displayExpr={'Name'}/>
                    <RequiredRule message={formatMessage('NutritionCategoryRequired')} />                       
                </Column>
            
                <Column
                    dataField={'PdfFile'}
                    caption={'PDF upload'}
                    visible={false}
                    width={70}
                    editCellRender={editCellRender} />
                <Column type="buttons" caption={formatMessage('Actions')} width={110}>
                    <Button  name="edit"  />
                    <Button name="delete" />
                    <Button icon="pdffile" hint={formatMessage('SeePDF')} name={formatMessage('SeePDF')} onClick={onDownloadPDFClick} />
                </Column>
            </DataGrid>
        </React.Fragment>
    )
}
let fileNameRecipe = "";

const nutritionCategoryData = {
                store: {
                type: 'odata',
                key: 'Oid',
                keyType: 'Guid',
                url: `${process.env.REACT_APP_URL}/api/odata/NutritionCategory`,
                version: 4,
                beforeSend: function (e) {
                e.headers = { 'Authorization': `Bearer ${localStorage.getItem("token")}`};
              },
           }
        };



