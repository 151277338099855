import React, { useState, useRef, useCallback } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  EmailRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { subscribeNewsletter } from '../../api/newsletter';
import './subscribe-newsletter.scss';
import { formatMessage } from 'devextreme/localization';
export default function SubscribeNewsletter() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ email: ''});

  const onSubmit = useCallback(async (e) => {
      e.preventDefault();
      const { email, firstName, lastName } = formData.current;
    setLoading(true);
      await subscribeNewsletter(email, firstName, lastName).then((result) => {
          if (result.status === 201) {
              notify(formatMessage('SuccessSubscribed'), 'success', 2000);
              setLoading(false);
          }
      }).catch(error =>
      {
          notify(error.response.data, 'error', 2000);
          setLoading(false);
      });

  }, [navigate]);

  return (
    <form className={'reset-password-form'} onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'email'}
          editorType={'dxTextBox'}
          editorOptions={emailEditorOptions}>
          <RequiredRule message={formatMessage('EmailRequired')} />
                  <EmailRule message={formatMessage('EmailInvalid')} />
          <Label visible={true} />
              </Item>
              <Item
                  dataField={'firstName'}
                  editorType={'dxTextBox'}
                  editorOptions={firstNameEditorOptions}>
                  <RequiredRule message={formatMessage('FirstNameRequired')} />
                  <Label visible={true} />
              </Item>
              <Item
                  dataField={'lastName'}
                  editorType={'dxTextBox'}
                  editorOptions={lastNameEditorOptions}>
                  <RequiredRule message={formatMessage('LastnameRequired')} />
                  <Label visible={true} />
              </Item>
        <ButtonItem>
          <ButtonOptions
            elementAttr={submitButtonAttributes}
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                  : formatMessage('Subscribe')
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'email' };
let firstNameEditorOptions = { stylingMode: 'filled', placeholder: formatMessage('FirstName'), mode: 'text' };
let lastNameEditorOptions = { stylingMode: 'filled', placeholder: formatMessage('LastName'), mode: 'text' };
const submitButtonAttributes = { class: 'submit-button' };
