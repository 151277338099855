import React, { useEffect, useRef, useCallback, useState } from 'react';
import TreeView from 'devextreme-react/tree-view';
import { navigation } from '../../app-navigation';
import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';
import './SideNavigationMenu.scss';
import { useAuth } from '../../contexts/auth';
import routes from '../../app-routes';


import * as events from 'devextreme/events';

export default function (props) {
    const {
        children,
        selectedItemChanged,
        openMenu,
        compactMode,
        onMenuReady
    } = props;

    const { user, getUserRole } = useAuth();
    const { isLarge } = useScreenSize();
    const [items, setItems] = useState();
    const { navigationData: { currentPath } } = useNavigation();

    useEffect(() => {
        (async function () {
            const result = await getUserRole();
            setItems(normalizePath(result));
        })();

        const treeView = treeViewRef.current && treeViewRef.current.instance;
        if (!treeView) {
            return;
        }

        if (currentPath !== undefined) {
            treeView.selectItem(currentPath);
            treeView.expandItem(currentPath);
        }

        if (compactMode) {
            treeView.collapseAll();
        }
    }, [currentPath, compactMode, getUserRole]);

    function normalizePath(role) {
        // T973518
        let newNavigation = filterNavigation(navigation,role);
        return newNavigation.map((item) => {
            if (item.path && !(/^\//.test(item.path))) {
                item.path = `/${item.path}`;
            }
            return { ...item, expanded: isLarge };
        });
    }

    // T973518
    function filterNavigation(navigation, role) {
        return navigation.filter(nav => {
            if (nav.path) {
                //check if path is allowed for user
                let matchingRoute = routes.find(route => route.path === nav.path),
                    routeUserTypes = matchingRoute.allowedUserTypes,
                    currUserType = role;
                if (routeUserTypes.includes(currUserType))
                    return true
                else
                    return false

            } else {
                if (nav.items) {
                    nav.items = filterNavigation(nav.items,role);
                }

                return true
            }
        })
    }

    const treeViewRef = useRef();
    const wrapperRef = useRef();
    const getWrapperRef = useCallback((element) => {
        const prevElement = wrapperRef.current;
        if (prevElement) {
            events.off(prevElement, 'dxclick');
        }

        wrapperRef.current = element;
        events.on(element, 'dxclick', e => {
            openMenu(e);
        });
    }, [openMenu]);

    return (
        <div
            className={'dx-swatch-additional side-navigation-menu'}
            ref={getWrapperRef}
        >
            {children}
            <div className={'menu-container'}>
                <TreeView
                    ref={treeViewRef}
                    items={items}
                    keyExpr={'path'}
                    selectionMode={'single'}
                    focusStateEnabled={false}
                    expandEvent={'click'}
                    onItemClick={selectedItemChanged}
                    onContentReady={onMenuReady}
                    width={'100%'}
                />
            </div>
        </div>
    );
}
