import React from 'react';
import './NotificationItem.scss';
import { formatDate } from "devextreme/localization";
export default function NotificationItem(item) {
    const createdAt = formatDate(new Date (item.CreatedAt), "dd-MM-yyyy HH:mm");
    return (
        <React.Fragment>
            <div className="item"><div className={`${item.Seen ? '' : 'red-dot'}`}></div>{item.Title}
                <div className="title">{item.Message}</div>
            </div>
            <div className={`date ${item.Seen ? '' : 'overlay'}`}> Date: {createdAt}</div>
        </React.Fragment>
    );
}
