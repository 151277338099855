import axios from 'axios';
const CALENDLY_AUTH_BASE_URL = process.env.REACT_APP_CALENDLY_AUTH_BASE_URL;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

export async function signIn(userName, password) {
    let userData = {};
    var isResponseOk = false;
    await axios.post(`/api/Authentication/Authenticate`, { userName, password }).then(res => {
        if (res.status === 200) {
            userData = JSON.parse(JSON.stringify(res.data));
            localStorage.setItem('user', JSON.stringify(res.data));
            localStorage.setItem('token', res.data.token);
            isResponseOk = res.data.isOk;
        }
        else {
            localStorage.removeItem("user");
        }
    }).catch(error => {
        userData = {
            isOk: isResponseOk,
            message: error.response.data
        };
    })
    return userData;
}

export async function refresh() {
    let userData = localStorage.getItem("user");
    try {
        const res = await axios.post(`/api/Authentication/Refresh`, { accessToken: userData.accessToken, refreshToken: userData.refreshToken });
        if (res.status === 200) {
            userData = JSON.parse(JSON.stringify(res.data));
            localStorage.setItem('user', JSON.stringify(res.data));
            localStorage.setItem('token', res.data.token);
        }
        else {
            localStorage.removeItem("user");
        }
        return userData;
    }
    catch (error) {
        return {
            isOk: false,
            message: error.message
        };
    }
}

export async function getCalendlyToken(code) {
    try {
        const res = await axios.post(`${CALENDLY_AUTH_BASE_URL}/oauth/token`, {
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            grant_type: 'authorization_code',
            code: code,
            redirect_uri: REDIRECT_URI
        });
        return res.data;
    } catch (error) {
        console.error('Error while getting token:', error);
    }
}

export async function updateCalendlyUser(result) {
    const res = await axios.post(`/api/Authentication/UpdateCalendlyUser`, result, await GetHeader());
    if (res.status === 200) {
        localStorage.setItem('user', JSON.stringify(res.data));
    }
    return res.data;
}

export async function getUser() {
  try {
      const res = await axios.get(`/api/Authentication/GetUser`, await GetHeader());
      return res.data;
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function updateUser(companies) {
    try {
        const res = await axios.post(`/api/Authentication/UpdateUser`, companies, await GetHeader());
        return res.data;
    }
    catch (error) {
        return error.message;
    }
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}

async function GetHeader() {
    const header = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
    }
    return header;
}
