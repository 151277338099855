import axios from 'axios';
export async function saveCompanyFormRequest(data) {
    const res = await axios.post(`/api/odata/Company`, data, await GetHeader());
    try {
        return res;
    }
    catch {
        return res;
    }
}

async function GetHeader() {
    const header = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
    }
    return header;
}