
import React, { useCallback } from 'react';
import './companies.scss';
import 'devextreme/data/odata/store';
import { useAuth } from '../../contexts/auth';
import DataGrid, {
    Button,
    Column,
    Pager,
    Paging,
    FilterRow,
    Editing,
    SearchPanel,
} from 'devextreme-react/data-grid';
import ColorBox from 'devextreme-react/color-box';
import { RequiredRule } from 'devextreme-react/form';
import FileUploader from 'devextreme-react/file-uploader';
import { formatMessage } from 'devextreme/localization';
export default function Companies() {
    const { user, signOut } = useAuth();
    const fileUploadURL = `${process.env.REACT_APP_URL}/api/Company/UploadLogo`;
    let fileUploaderRef = React.createRef();
    function editColorCellRender(cell) {
        const onValueChanged = (e) => cell.setValue(e.value);
        return (
            <>
            <ColorBox
              defaultValue={cell.value}
              onValueChanged={onValueChanged}
              inputAttr={{ 'aria-label': 'Default mode' }}
            />
            </>
        );
    }

    function editCellRender(cellInfo) {
        let headers = {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            CompanyName : cellInfo.values[0]
        }
        return (
            <>
                <FileUploader ref={fileUploaderRef} uploadHeaders={headers}
                    name="file"
                    accept="image/*"
                    maxFileSize={1024 * 1024}
                    allowedFileExtensions={['.png']}
                    multiple={false} uploadMode="instantly"
                    uploadUrl={fileUploadURL}
                    onUploadError={onUploadError} />
            </>
        );
    }

    const onUploadError = useCallback(e => {
        let xhttp = e.request;
        if (xhttp.status === 400) {
            e.message = e.error.responseText;
        }
        if (xhttp.readyState === 4 && xhttp.status === 0) {
            e.message = "Connection refused";
        }
 
    }, []);


    const buildFilter = (idArray) => {
        const filter = [];
        idArray.forEach((id, index) => {
          if (index > 0) {
            filter.push('or');
          }
          filter.push(['Oid', '=', `'${id}'`]);
        });
        return filter;
      };

    const dataSource = {
        store: {
            type: 'odata',
            version: 4,
            paginate: false,
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/Company`,
            errorHandler: (error) => {
                if (error.httpStatus === 401) {
                    signOut(true);
                }
            },
            beforeSend: function (e) {
                e.headers = {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                };

            },
        },
        select: [
            'Oid',
            'Name',
            'Color',
            'Logo',
            'UseCompanyLayout'
        ],
        filter: buildFilter(user.companies)
    };
    return (
        <React.Fragment>
            <h2 className={'content-block'}>{formatMessage('Companies')}</h2>
            <DataGrid
                className={'dx-card wide-card'}
                dataSource={dataSource}
                showBorders={false}
                focusedRowEnabled={true}
                defaultFocusedRowIndex={0}
                columnAutoWidth={true}
                columnHidingEnabled={true}>
                <Editing
                    mode="popup"
                    allowUpdating={true}>
                </Editing>
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />
                <SearchPanel width={500} visible={true} />
                <Column
                    dataField={'Name'}
                    caption={formatMessage('FullName')}>
                    <RequiredRule message={formatMessage('NameRequired')} />  
                </Column>
                <Column
                    dataField={'Color'}
                    caption={formatMessage('CompanyColor')}
                    visible={false}
                    width={70}
                    editCellRender={editColorCellRender} />
                <Column
                    dataField={'UseCompanyLayout'}
                    editorType="dxCheckBox"
                    caption={formatMessage('UseCompanyLayout')}>                
                </Column>
                <Column
                    dataField={'LogoFile'}
                    caption={formatMessage('CompanyLogo')}
                    visible={false}
                    width={70}
                    editCellRender={editCellRender} /> 
                <Column type="buttons" caption={formatMessage('Actions')} width={110}>
                    <Button  name="edit"  />
                </Column>
            </DataGrid>
        </React.Fragment>
    )
}




