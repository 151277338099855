import React, { useCallback, useState } from 'react';
import { useNavigate } from "react-router-dom";
import 'devextreme/data/odata/store';
import DataGrid, {
  Button,
  Column,
  Pager,
  Paging,
  Editing,
  Lookup,
  FilterRow,
  RequiredRule as Required,
} from 'devextreme-react/data-grid';
import { CheckBox } from 'devextreme-react/check-box';
import Tabs, { Item } from 'devextreme-react/tab-panel';
import { useAuth } from '../../contexts/auth';
import { formatMessage } from 'devextreme/localization';
import { RequiredRule } from 'devextreme-react/form';
import TagBoxComponent from './tagbox.js';
export default function Newsletters() {
    const { signOut } = useAuth();
    const navigate = useNavigate();
    const [weeksToSend] = useState(Array.from({ length: 24 }, (_, index) => index + 1));
    const dataSource = {
        store: {
            type: 'odata',
            key: 'Oid',
            version: 4,
            url: `${process.env.REACT_APP_URL}/api/odata/EmailNewsletter`,
            errorHandler: (error) => {
                if (error.httpStatus === 401) {
                    signOut(true);
                }
            },
            beforeSend: function (e) {
                e.headers = {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                };
            },
        },
        select: [
            'Oid',
            'WeekNumber',
            'Company',
        ],
        expand: ['Company'],
    };

    const dataSourceEmailTemplates = {
        store: {
            type: 'odata',
            key: 'Oid',
            version: 4,
            url: `${process.env.REACT_APP_URL}/api/odata/EmailTemplate`,
            errorHandler: (error) => {
                if (error.httpStatus === 401) {
                    signOut(true);
                }
            },

            beforeSend: function (e) {
                e.headers = {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                };
            },
            onLoaded: function (e) {
                e.forEach(element => {
                    const weeksStringArray = element["WeeksToSendEmail"].split(',').map(str => str.trim());
                    const weeksIntegerArray = weeksStringArray.map(str => parseInt(str, 10));
                    element["WeeksToSendEmail"] = weeksIntegerArray;
                });
            },
            onUpdating: function (key, values) {
                if (values["WeeksToSendEmail"])
                {
                    values["WeeksToSendEmail"] = values["WeeksToSendEmail"].join(',');
                }
            },
            onInserting: function (key, values) {
                if (key["WeeksToSendEmail"]) {
                    key["WeeksToSendEmail"] = key["WeeksToSendEmail"].join(',');
                }
            }       
        },
        select: [
            'Oid',
            'Name',
            'FileName',
            'Active',
            'Company',
            'WeeksToSendEmail',
        ],
        expand: ['Company'],
    };

    const cellTemplate = (container, options) => {
        const noBreakSpace = '\u00A0';
        const assignees = (options.value || []).map((assigneeId) =>
            options.column.lookup.calculateCellValue(assigneeId));
        const text = assignees.join(', ');
        container.textContent = text || noBreakSpace;
        container.title = text;
    };
    function calculateFilterExpression(filterValue, selectedFilterOperation, target) {
        if (target === 'search' && typeof filterValue === 'string') {
            return [this.dataField, 'contains', filterValue];
        }
        return (rowData) => (rowData.AssignedEmployee || []).indexOf(filterValue) !== -1;
    }

    const dataSourceSubscribers = {
        store: {
            type: 'odata',
            key: 'Oid',
            version: 4,
            url: `${process.env.REACT_APP_URL}/api/odata/NewsletterSubscriber`,
            errorHandler: (error) => {
                if (error.httpStatus === 401) {
                    signOut(true);
                }
            },
            beforeSend: function (e) {
                e.headers = {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                };
            },
        },
        select: [
            'Oid',
            'FirstName',
            'LastName',
            'FullName',
            'Email',
            'ReceiveNewsletter',
        ]
    };

    const companiesData = {
        store: {
            type: 'odata',
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/Company`,
            version: 4,
            beforeSend: function (e) {
                e.headers = { 'Authorization': `Bearer ${localStorage.getItem("token")}` };
            },
        },
        select: [
            'Oid',
            'Name',
        ]
    };

    const onEditClick = useCallback((e) => {
        var oid = e.row.data.Oid._value.toString();
        navigate(`/newsletterDetails/${oid}`, { state: { Oid: oid, Week: e.row.data.WeekNumber, FileName: e.row.data.FileName, CompanyName: e.row.data.Company?.Name  } });
    },[])

  return (
    <React.Fragment>
          <h2 className={'content-block'}>{formatMessage('Newsletter')}</h2>
          <Tabs>
              <Item title={formatMessage('NewsletterWeekly')}>
                  <DataGrid
                      className={'dx-card wide-card'}
                      dataSource={dataSource}
                      showBorders={false}
                      columnHidingEnabled={true}>
                      <Paging defaultPageSize={20} />
                      <Pager showPageSizeSelector={true} showInfo={true} />
                      <Editing
                          mode="form"
                          allowUpdating={true}
                          allowAdding={true}
                          allowDeleting={true} />
                      <Column
                          alignment="Left"
                          sortOrder="asc"
                          dataField={'WeekNumber'}
                          caption={'Week number'} >
                          <Required />
                  </Column>
                      <Column
                          dataField={'Company.Oid'}
                          caption={formatMessage('Company')}
                          groupIndex={0}
                          hidingPriority={6}>
                          <Lookup
                              dataSource={companiesData}
                              displayExpr={'Name'}
                              valueExpr={'Oid'} >
                          </Lookup>
                          <Required />
                      </Column>
                      <Column type="buttons" caption="Actions" width={110}>
                          <Button icon="edit" hint="Edit email" name="Edit" onClick={onEditClick} />
                          <Button name="delete" />
                      </Column>
                  </DataGrid>
              </Item>
              <Item title={formatMessage('EmailTemplates')}>
                  <DataGrid
                      className={'dx-card wide-card'}
                      dataSource={dataSourceEmailTemplates}
                      showBorders={false}
                      columnHidingEnabled={true}>
                      <Paging defaultPageSize={20} />
                      <Pager showPageSizeSelector={true} showInfo={true} />
                      <Editing
                          mode="form"
                          allowUpdating={true}
                          allowAdding={true}
                          allowDeleting={true} />
                      <Column
                          alignment="Left"
                          sortOrder="asc"
                          dataField={'Name'}
                          caption={'Name'}>
                      <RequiredRule message="Name is required" />
                  </Column>
                      <Column
                          alignment="Left"
                          dataField={'FileName'}
                          caption={'FileName'}>
                  <RequiredRule message="FileName is required" />
                      </Column>
                      <Column
                          dataField={'Company.Oid'}
                          caption={formatMessage('Company')}
                          groupIndex={0}
                          hidingPriority={6}>
                          <Lookup
                              dataSource={companiesData}
                              displayExpr={'Name'}
                              valueExpr={'Oid'} >
                          </Lookup>
                          <Required />
                      </Column>
                      <Column
                          dataField={'WeeksToSendEmail'}
                          caption={formatMessage('Weeks')}
                          allowSorting={false}
                          editCellComponent={TagBoxComponent}
                          cellTemplate={cellTemplate}
                          calculateFilterExpression={calculateFilterExpression}>
                          <Lookup value dataSource={weeksToSend} />
                          <RequiredRule />
                      </Column>
                  <Column
                      dataField={'Active'}
                          caption={'Active'} >
                          <CheckBox />
                      </Column>
                      <Column type="buttons" caption="Actions" width={110}>
                          <Button name="edit" />
                          <Button icon="doc" hint="Edit email" name="Edit" onClick={onEditClick} />
                          <Button name="delete" />
                      </Column>
                  </DataGrid>
              </Item>
              <Item title="Subscribers">
                  <DataGrid
                      className={'dx-card wide-card'}
                      dataSource={dataSourceSubscribers}
                      showBorders={false}
                      columnHidingEnabled={true}>
                      <Paging defaultPageSize={20} />
                      <Pager showPageSizeSelector={true} showInfo={true} />
                      <FilterRow visible={true} />
                      <Editing
                          mode="row"
                          allowUpdating={true}
                          allowAdding={true}
                          allowDeleting={true} />
                      <Column dataField={'FirstName'} sortOrder="asc" />
                      <Column dataField={'LastName'} />
                      <Column
                          alignment="Left"
                          dataField={'Email'}
                          caption={'Email'} >
                          <RequiredRule message="Email is required" />
                  </Column>
                      <Column
                          dataField={'ReceiveNewsletter'}
                          width={110}
                          caption={'Subscribed'} />
                      <Column type="buttons" caption="Actions" width={110}>
                          <Button name="delete" />
                          <Button name="save" />
                      </Column>
                  </DataGrid>
              </Item>
          </Tabs>
    </React.Fragment>
)}

