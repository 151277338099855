import React from 'react';
import { formatDate } from 'devextreme/localization';

const Appointment = (props) => {
    const { appointmentData } = props.data;
    return (
        <div className="showtime-preview">
            <div><strong>{appointmentData.invite.name}</strong></div>
            <div>{appointmentData.name}</div>
            <div>
                {formatDate(new Date(appointmentData.start_time), 'shortTime')}
                {' - '}
                {formatDate(new Date(appointmentData.end_time), 'shortTime')}
            </div>
        </div>
    );
};

export default Appointment;
