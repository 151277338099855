import axios from 'axios';

export async function postCopyDietPlanRequest(personalFileOid, fromDay, toDays, fromDietVariation, toDietVariation) {
    const res = await axios.post(`/api/DietPlanItemActions/CopyDietPlan?personalFileOid=${personalFileOid}&fromDay=${fromDay}&toDays=${toDays}&fromDietVariation=${fromDietVariation}&toDietVariation=${toDietVariation}`, toDays, await GetHeader());
    try {
        return res;
    }
    catch {
        return res;
    }
}

export async function copyClientDiet(formPersonalFileOid, toPersonalFileOid, fromDietVariation, toDietVariation) {
    const res = await axios.post(`/api/DietPlanItemActions/CopyDietPlanToClient?formPersonalFileOid=${formPersonalFileOid}&toPersonalFileOid=${toPersonalFileOid}&fromDietVariation=${fromDietVariation}&toDietVariation=${toDietVariation}`, null, await GetHeader());
    try {
        return res;
    }
    catch {
        return res;
    }
}

export async function findAndReplaceDietItem(personalFileOid, dietVariation, findItem, replaceItem, replaceItemValue) {
    let jsonObject = {
            personalFileOid: personalFileOid._value,
            dietVariation: dietVariation,
            findItem: findItem,
            replaceItem: replaceItem,
            replaceItemValue: replaceItemValue
    };
    const res = await axios.post(`/api/DietPlanItemActions/FindAndReplaceDietItem`, JSON.stringify(jsonObject), await GetHeader());
    try {
        return res;
    }
    catch {
        return res;
    }
}


export async function reSendDietPlan(personalFileOid, weekNumber) {
    const res = await axios.post(`/api/RecepiePdf/SendDietPlanToClient?personalFileOid=${personalFileOid}&weekNumber=${weekNumber}`, null, await GetHeader());
    try {
        return res;
    }
    catch {
        return res;
    }
}

export async function getDietPlanDocument(personalFileOid, dietVariation) {
    const res = await axios.get(`/api/DietPlanItemActions/GetDietPlanDocument2?PersonalFileOid=${personalFileOid}&DietVariation=${dietVariation}`, await GetHeaderBlob());
    try {
        return res;
    }
    catch {
        return res;
    }
}

async function GetHeader() {
    const header = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
    }
    return header;
}

async function GetHeaderBlob() {
    const headerBlob = {
        responseType: "blob",
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
    };
    return headerBlob;
}