import { HomePage, TasksPage, ProfilePage, RelationPage, RelationDetails, RecipesPage, NewslettersPage, NewslettersDetails, CompaniesPage } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    {
        path: '/home',
        element: HomePage,
        allowedUserTypes: ["Admin", "Owner"]
    },
    {
    path: '/companies',
    element: CompaniesPage,
    allowedUserTypes: ["Admin", "Owner"]
    },
    {
        path: '/tasks',
        element: TasksPage,
        allowedUserTypes: ["Admin","Owner"]
    },
    {
        path: '/newsletters',
        element: NewslettersPage,
        allowedUserTypes: ["Admin", "Owner","External"]
    },
    {
        path: '/newsletterDetails/:Oid',
        element: NewslettersDetails,
        allowedUserTypes: ["Admin", "Owner", "External"]
    },
    {
        path: '/profile',
        element: ProfilePage,
        allowedUserTypes: ["Admin", "Owner"]
    },
    {
        path: '/relation',
        element: RelationPage,
        allowedUserTypes: ["Admin", "Owner", "External"]
    },
    {
        path: '/relationDetails/:Oid',
        element: RelationDetails,
        allowedUserTypes: ["Admin", "Owner", "External"]
    },
    {
        path: '/recipes',
        element: RecipesPage,
        allowedUserTypes: ["Admin", "Owner"]
    }
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
