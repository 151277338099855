
import React, { useState} from 'react';
import { useNavigate } from "react-router-dom";
import './relation.scss';
import 'devextreme/data/odata/store';
import { useAuth } from '../../contexts/auth';
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Editing,
    SearchPanel,
    Form,
    Grouping,
    GroupItem,
    Summary,
    Popup,
    Lookup,
    Scrolling,
    Toolbar, Item as GridItem
} from 'devextreme-react/data-grid';
import { Button as FormButton } from 'devextreme-react/button';
import { Item, RequiredRule } from 'devextreme-react/form';
import { Popup as CenterPopup, ToolbarItem } from 'devextreme-react/popup';
import CompanyForm from '../.././components/companyForm/companyForm';
import NewsletterCampaignForm from '../.././components/newsletterCampaignForm/newsletterCampaignForm';
import { formatMessage} from 'devextreme/localization';
export default function Relation() {
    const navigate = useNavigate();
    const { user, signOut } = useAuth();
    const [addCompanyPopupVisible, setAddCompanyPopupVisible] = useState(false);
    const [addCampaignPopupVisible, setAddCampaignPopupVisible] = useState(false);
    function navigateToProfile(e) {
        if (e.rowType === "group") return;
        e.data.Oid = e.data.Oid._value.toString();
        navigate(`/relationDetails/${e.data.Oid}`, { state: { data: e.data } } );
    }

    const dataSource = {
        store: {
            type: 'odata',
            version: 4,
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/Relation`,
            errorHandler: (error) => {
                if (error.httpStatus === 401) {
                    signOut(true);
                }
            },
            beforeSend: function (e) {
                e.headers = {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                };

            },
            onInserting: function (values, key) {
                if (values.Company) {
                    values["CompanyOid"] = values.Company.Oid._value.toString();
                    delete values.NutritionCategory;
                }
            },
            onUpdating: function (key, values) {
                if (values.Company) {
                    values["CompanyOid"] = values.Company.Oid._value.toString();
                }
            }
        },
        select: [
            'Oid',
            'RelationId',
            'FirstName',
            'LastName',
            'Birthday',
            'FullName',
            'Gender',
            'Age',
            'Email',
            'TelephoneNumber',
            'Address',
            'AddressNr',
            'AddressAndNr',
            'PostalCode',
            'City',
            'ActivePersonalFile',
            'Company',
            'NewsletterCampaign',
            'ReceiveNewsletter'
        ],
        expand: ['PersonalFiles', 'ActivePersonalFile', 'Company', 'NewsletterCampaign'],
    };

    const companiesData = {
        store: {
            type: 'odata',
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/Company`,
            version: 4,
            beforeSend: function (e) {
                e.headers = { 'Authorization': `Bearer ${localStorage.getItem("token")}` };
            },
        }
    };

    const newsletterCampaignData = {
        store: {
            type: 'odata',
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/Company`,
            version: 4,
            beforeSend: function (e) {
                e.headers = { 'Authorization': `Bearer ${localStorage.getItem("token")}` };
            },
        }
    };

    const showAddCompanyPopup = (e) => {
        if (addCompanyPopupVisible) {
            setAddCompanyPopupVisible(false);
        }
        setAddCompanyPopupVisible(true);
    }

    const showAddCampaignPopup = (e) => {
        if (addCampaignPopupVisible) {
            setAddCampaignPopupVisible(false);
        }
        setAddCampaignPopupVisible(true);
    }

    const hideInfo = () => {
        setAddCompanyPopupVisible(false);
        setAddCampaignPopupVisible(false);
    }

    const closeButtonOptions = {
        text: 'Close',
        onClick: hideInfo
    };

    return (
        <React.Fragment>
            <h2 className={'content-block'}>{formatMessage('Relations')}</h2>
            <div>
                <CenterPopup
                    visible={addCompanyPopupVisible}
                    onHiding={hideInfo}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showCloseButton={false}
                    showTitle={true}
                    title={formatMessage('AddCompany')}
                    width={500}
                    height={400} >
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="top"
                        location="after"
                        options={closeButtonOptions} />
                    <CompanyForm onComplete={hideInfo} />
                </CenterPopup>
                <CenterPopup
                    visible={addCampaignPopupVisible}
                    onHiding={hideInfo}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    showCloseButton={false}
                    showTitle={true}
                    title={formatMessage('AddNewsletterCampaign')}
                    width={500}
                    height={400} >
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="top"
                        location="after"
                        options={closeButtonOptions} />
                    <NewsletterCampaignForm onComplete={hideInfo} />
                </CenterPopup>
            </div>
            <DataGrid
                className={'dx-card wide-card'}
                dataSource={dataSource}
                onRowClick={navigateToProfile}
                showBorders={false}
                focusedRowEnabled={true}
                defaultFocusedRowIndex={0}
                columnAutoWidth={true}
                height='100%'
                columnHidingEnabled={true}>
                <Grouping autoExpandAll={true} />
                <Scrolling mode="virtual" />
                <Editing
                    mode="popup"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={true}>
                    <Popup title="Relatie Info" showTitle={true} width={700} height={525} />
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2}>
                            <Item dataField="FirstName" >
                                <RequiredRule message={formatMessage('FirstNameRequired')} />  
                            </Item>
                            <Item dataField="LastName" >
                                <RequiredRule message={formatMessage('LastnameRequired')} />
                            </Item>
                            <Item dataField="Birthday"  editorType="dxDateBox"
                                editorOptions={{ width: '100%', type: 'date' }} >
                                <RequiredRule message={formatMessage('BirthdayRequired')} />
                            </Item>
                            <Item dataField="Gender" editorType="dxSelectBox" >
                                <RequiredRule message={formatMessage('GenderRequired')} />
                            </Item>
                            <Item dataField="Email" >
                                <RequiredRule message={formatMessage('EmailRequired')} />                       
                             </Item>
                                <Item dataField="ReceiveNewsletter" widget="30%" />
                            <Item dataField="TelephoneNumber"
                                colCount={2} colSpan={2}>
                                <RequiredRule message={formatMessage('TelephoneRequired')} />
                            </Item>
                            <Item dataField="Company.Oid" editorType="dxSelectBox"
                                colCount={2} colSpan={2}>
                            </Item>
                            <Item dataField="NewsletterCampaign.Oid" editorType="dxSelectBox"
                                colCount={2} colSpan={2}>
                                <RequiredRule message={formatMessage('FieldRequired')} />
                            </Item>
                            <Item dataField="Address">
                                <RequiredRule message={formatMessage('AddressRequired')} />
                                </Item>
                            <Item dataField="AddressNr" editorOptions={{ width: '30%',}}  >
                                <RequiredRule message={formatMessage('AddressNrRequired')} />
                            </Item>
                            <Item dataField="City" >
                                <RequiredRule message={formatMessage('CityRequired')} />
                            </Item>
                            <Item dataField="PostalCode">
                                <RequiredRule message={formatMessage('PostalcodeRequired')} />
                            </Item>

                        </Item>
                    </Form>
                </Editing>
                <Toolbar>
                    <GridItem
                        name="addRowButton"/>
                    <GridItem>
                        <FormButton text={formatMessage('AddCompany')}
                        icon="product"
                        onClick={showAddCompanyPopup} />
                    </GridItem>
                    <GridItem>
                        <FormButton visible={false} text={formatMessage('AddNewsletterCampaign')}
                            icon="email"
                            onClick={showAddCampaignPopup} />
                    </GridItem>
                </Toolbar>
                <Paging defaultPageSize={10} />
                <Pager showPageSizeSelector={true} showInfo={true} />
                <FilterRow visible={true} />
                <SearchPanel width={500} visible={true} />
                <Column dataField={'RelationId'} caption="ID"  width={100} hidingPriority={2} />
                <Column visible={false} dataField={'FirstName'} defaultSortOrder="asc" caption={formatMessage('FirstName')} />
                <Column visible={false} dataField={'LastName'} caption={formatMessage('LastName')} />
                <Column visible={false} dataField={'Birthday'} caption={formatMessage('Birthday')} />
                <Column visible={false} dataField={'Address'} caption={formatMessage('Address')} />
                <Column visible={false} dataField={'AddressNr'} caption={formatMessage('AddressNr')} />
                <Column visible={false} dataField={'ReceiveNewsletter'} />
                <Column
                    visible={false}
                    dataField={'NewsletterCampaign.Oid'}
                    caption={formatMessage('NewsletterCampaign')}
                    hidingPriority={6}>
                    <Lookup
                        dataSource={newsletterCampaignData}
                        displayExpr={'Name'}
                        valueExpr={'Oid'} >
                    </Lookup>
                </Column>
                <Column
                    dataField={'FullName'}
                    width={190}
                    caption={formatMessage('FullName')}
                    hidingPriority={8} />
                <Column
                    dataField={'Gender'}
                    caption={formatMessage('Gender')}
                    width={100}
                    hidingPriority={6}>
                    <Lookup
                        dataSource= {Gender}
                        displayExpr= {'Name'}
                        valueExpr ={'Value'} >
                    </Lookup>
                    </Column>
                <Column
                    dataField={'Age'}
                    caption={formatMessage('Age')}
                    width={100}
                    allowSorting={false}
                    hidingPriority={7} />
                <Column
                    dataField={'Email'}
                    caption={formatMessage('Email')}
                    hidingPriority={3} />
                <Column
                    dataField={'TelephoneNumber'}
                    caption={formatMessage('Telephone')}
                    hidingPriority={3} />
                <Column
                    dataField={'AddressAndNr'}
                    caption={formatMessage('Address')}
                    hidingPriority={3} />
                <Column
                    dataField={'Company.Oid'}
                    caption={formatMessage('Company')}
                    groupIndex={0}
                    hidingPriority={6}>
                    <Lookup
                        dataSource={companiesData}
                        displayExpr={'Name'}
                        valueExpr={'Oid'} >
                    </Lookup>
                </Column>
                <Column
                    dataField={'PostalCode'}
                    caption={formatMessage('Postalcode')}
                    hidingPriority={3} />
                <Column
                    dataField={'City'}
                    caption={formatMessage('City')}
                    hidingPriority={3} />
                <Summary>
                    <GroupItem
                        column="OId"
                        summaryType="count" />
                </Summary>
            </DataGrid>
        </React.Fragment>
    )
}

const Gender = [
    {
        Name: "Male",
        Value: 0,

    },
    {
        Name: "Female",
        Value: 1,

    },
];




