import axios from 'axios';
export async function getActivePersonalFileRequest(relationOid) {
    const res = await axios.get(`/api/odata/Relation(${relationOid})?$expand=ActivePersonalFile($expand=PersonalGoal,NutritionCategory)`, await GetHeader());
    try {
        return res;
    }
    catch {
        return res;
    }
}

export async function getActiveRelations(relationOid) {
    const res = await axios.get(`/api/odata/Relation?$expand=ActivePersonalFile`, await GetHeader());
    try {
        return res;
    }
    catch {
        return res;
    }
}

async function GetHeader() {
    const header = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
    }
    return header;
}
