import React, { useCallback, useState, useEffect } from 'react';
import 'devextreme/data/odata/store';
import DataGrid, {
  Button,
  Column,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  Format,
  Toolbar, Item
} from 'devextreme-react/data-grid';
import moment from 'moment';
import { useAuth } from '../../contexts/auth';
import SelectBox from 'devextreme-react/select-box';
import { Button as FormButton } from 'devextreme-react/button';
import { Lookup } from 'devextreme-react/lookup';
import { reSendDietPlan } from '../../api/dietItemPlan';
import { reSendNewsletter, reSendNewsletters } from '../../api/newsletter';
import notify from 'devextreme/ui/notify';
import { formatMessage } from 'devextreme/localization';
export default function Task() {
    const { user, signOut } = useAuth();
    const [selectedWeekNr, setWeekNr] = useState(getFormatedWeekNumber(moment().format('YYYY'), moment().format('W')));
    const [selectedPerson, setPerson] = useState({});
    const [weekNumbersList, setWeekNumbersList] = useState([]);
    useEffect(() => {
        var weeks =  getWeekNumbersForYear(moment().format('YYYY'));
        setWeekNumbersList(weeks);
    }, []);

    function getWeekNumbersForYear(year) {
        const weeks = [];

        for (let week = 1; week <= 52; week++) {
            const result = getFormatedWeekNumber(year, week);
            weeks.push(result);
        }

        return weeks;
    }

    function getFormatedWeekNumber(year, week)
    {
        const weekStart = moment().year(year).isoWeek(week).startOf('isoWeek');
        const formattedWeekNumber = week < 10 ? `0${week}` : week;
        const result = `${year}${formattedWeekNumber}`;
        return parseInt(result, 10);
    }

    const dataSource = {
        store: {
            type: 'odata',
            key: 'Oid',
            version: 4,
            url: `${process.env.REACT_APP_URL}/api/odata/EmailPlan`,
            filterToLower: true,
            errorHandler: (error) => {
                if (error.httpStatus === 401) {
                    signOut(true);
                }
            },
            beforeSend: function (e) {
                e.headers = {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                };
            },
        },
        expand: 'PersonalFile(expand=Relation(expand=Company))',
        filter: ['WeekNumber', '=', selectedWeekNr],
        select: [
            'Oid',
            'CreatedAt',
            'WeekNumber',
            'EmailStatus',
            'TryToSend',
            'SendAt',
            'EmailSendTo',
            'PersonalFile',
            'EmailType'
        ]
    };
    const relationData = {
        store: {
            type: 'odata',
            version: 4,
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/Relation`,
            errorHandler: (error) => {
                if (error.httpStatus === 401) {
                    signOut(true);
                }
            },
            beforeSend: function (e) {
                e.headers = {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                };

            },
        },
        select: [
            'Oid',
            'FullName',
            'ActivePersonalFile'
        ],
        expand: ['ActivePersonalFile'],
        filter: ['ActivePersonalFile', '<>', null],
        sort: [
            { getter: "FirstName", desc: false }
        ]
    };

    const onResendClick = useCallback(async (e) => {
        if (e.row.data.EmailType === "Newsletter") {
            await reSendNewsletter(e.row.data.EmailSendTo, e.row.data.WeekNumber).then((result) => {

                notify(result.data, 'success', 2000);
            }).catch(error => {
                notify(error.message, 'error', 2000);
            });
        }
        else
        {
            await reSendDietPlan(e.row.data.PersonalFile.Oid, e.row.data.WeekNumber).then((result) => {

                notify(result.data, 'success', 2000);
            }).catch(error => {
                notify(error.message, 'error', 2000);
            });
        }

    }, [])
    const onSendToClientClick = useCallback(async (e) => {
        if (selectedPerson)
        {
            await reSendDietPlan(selectedPerson.ActivePersonalFile.Oid._value, selectedWeekNr).then((result) => {

                notify(result.data, 'success', 2000);
            }).catch(error => {
                console.log(error)
                notify(error.response.data, 'error', 2000);
            });
        }

    }, [selectedPerson])

    const onResendNewslettersClick = useCallback(async (e) => {
            await reSendNewsletters(selectedWeekNr).then((result) => {

                notify(result.data, 'success', 2000);
            }).catch(error => {
                console.log(error)
                notify(error.response.data, 'error', 2000);
            });
    }, [selectedWeekNr])
    
    const selectedWeekNrChanged = useCallback((e) => {
        setWeekNr(e.value);
    }, []);
    const selectedPersonChanged = useCallback((e) => {
        setPerson(e.value);
    }, []);
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Email Log</h2>
          <DataGrid
              className={'dx-card wide-card'}
              dataSource={dataSource}
              showBorders={false}
              columnAutoWidth={true}
              columnHidingEnabled={true}>
        <Paging defaultPageSize={20} />
        <Pager showPageSizeSelector={true} showInfo={true} />
              <FilterRow visible={true} />
              <HeaderFilter visible={true} />
              <Toolbar>
                  <Item location="before">
                      <SelectBox
                          width="225"
                          items={weekNumbersList}
                          value={selectedWeekNr}
                          onValueChanged={selectedWeekNrChanged} />
                  </Item>
                  <Item location="before">
                      <div><p>{formatMessage('TodayIsWeek')} {moment().format('WW')}</p></div>
                  </Item>
                  <Item location="after">
                      <Lookup
                          value={selectedPerson}
                          searchExpr="FullName"
                          searchEnabled={true}
                          width="225"
                          dataSource={relationData}
                          onValueChanged={selectedPersonChanged}
                          applyValueMode="instantly"
                          displayExpr="FullName" />
                  </Item>
                  <Item location="after">
                      <FormButton
                      icon="email"
                      text="Send Dietplan"
                      type="success"
                      onClick={onSendToClientClick} />
                  </Item>
                  <Item location="after">
                      <FormButton
                          icon="email"
                          text="Resend Newsletters"
                          type="success"
                          onClick={onResendNewslettersClick} />
                  </Item>
              </Toolbar>
              <Column
                  width={160}
                  defaultSortOrder="desc"
                  dataField={'CreatedAt'}
                  caption={formatMessage('CreatedAt')}
                  format="dd-MM-yyyy HH:mm"
                  dataType="date">
              </Column>
        <Column
          dataField={'WeekNumber'}
          width={130}
          caption={formatMessage('WeekNumber')}
         />
        <Column
          dataField={'PersonalFile.Relation.FullName'}
          width={200}
          caption={formatMessage('SendTo')}
              />
        <Column
            dataField={'EmailSendTo'}
            width={220}
            caption={'Email'}
        />
        <Column
          dataField={'EmailStatus'}
          caption={'Status'}
              />
              <Column
                  dataField={'TryToSend'}
                  caption={formatMessage('TryToSend')}
                  format="dd-MM-yyyy HH:mm"
                  dataType="date">
              </Column>
              <Column
                  dataField={'SendAt'}
                  caption={formatMessage('SendAt')}
                  format="dd-MM-yyyy HH:mm"
                  dataType="date">

              </Column>
              <Column
                  dataField={'EmailType'}
                  caption="Soort">
              </Column>
              <Column
                  dataField={'PersonalFile.Relation.Company.Name'}
                  caption={formatMessage('Company')}
                  groupIndex={0}
                  hidingPriority={6}/>
              <Column type="buttons" caption={formatMessage('Actions')} width={110}>
                  <Button icon="refresh" hint={formatMessage('Resend')} name={formatMessage('Resend')} onClick={onResendClick} /> 
              </Column>
      </DataGrid>
    </React.Fragment>
)}

