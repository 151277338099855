import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser as getUserRequest, refresh as refreshRequest, signIn as sendSignInRequest, updateUser as updateUserRequest, getCalendlyToken as getCalendlyTokenRequest, updateCalendlyUser } from '../api/auth';


function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      const result = await getUserRequest();
    if (result.isOk) {
        setUser(result);
      }
      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (email, password) => {
    const result = await sendSignInRequest(email, password);
    if (result.isOk) {
      setUser(result);
    }

    return result;
  }, []);


    const getCalendlyToken = useCallback(async (code) => {
        let result = await getCalendlyTokenRequest(code);
        if (result)
        {
            result = await updateCalendlyUser(result);
            if (result.isOk) {
                const userData = JSON.parse(JSON.stringify(result));
                setUser(userData);
            }
        }

        return result;
    }, []);

    const updateUser = useCallback(async (companies) => {
        const result = await updateUserRequest(companies);
        return result;
    }, []);

    const getUserRole = useCallback(async () => {
        const result = await getUserRequest();
        if (result.isOk) {
            return result.role;
        }
    }, []);

    const getUserData = useCallback(async () => {
        const result = await getUserRequest();
        if (result.isOk) {
            return result;
        }
    }, []);

    const signOut = useCallback(async (refresh) => {
        var response =
        {
            isOk : false
        };
        if (refresh === true) {
            response = await refreshRequest();

        }

        if (refresh === false || response.isOk === false) {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            setUser(undefined);
        }
        window.location.reload();
   
  }, []);

    return (
        <AuthContext.Provider value={{ user, signIn, signOut, loading, getUserRole, getUserData, updateUser, getCalendlyToken }} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
