import {formatMessage} from 'devextreme/localization';
export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
    },
    {
        text: 'Relaties',
        path: '/relation',
        icon: 'group'
    },
    {
      text: 'Companies',
      path: '/companies',
      icon: 'product'
  },
    {
        text: 'Recipes',
        path: '/recipes',
        icon: 'food'
    },
  {
    text: 'Email',
    icon: 'folder',
    items: [
      {
        text: 'Email plan',
        icon:'email',
        path: '/tasks'
        },
        {
            text: 'Newsletters',
            icon: 'share',
            path: '/newsletters'
        }
    ]
    }
  ];
