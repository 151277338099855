import React, { useCallback, useState, useRef } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import { Button } from 'devextreme-react/button';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import { DataManager, ODataV4Adaptor, Query, Predicate } from '@syncfusion/ej2-data';
import { KanbanComponent, ColumnsDirective, ColumnDirective } from "@syncfusion/ej2-react-kanban";
import './kanban.scss';
import { EatMoments, days, DietVariation } from '../../Enums';
import { getDietPlanDocument } from '../../api/dietItemPlan';
import CopyClientDietDialog from '../.././components/popups/CopyClientDietDialog';
import FindReplaceDialog from '../.././components/popups/FindReplaceDialog';
import SelectDaysForm from '../.././components/selectDaysForm/selectDaysForm';
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import SelectBox from 'devextreme-react/select-box';
import { formatMessage } from 'devextreme/localization';
function Swimlane(props) {
    const [copyClientDietPopupVisible, setCopyClientDietPopupVisible] = useState(false);
    const [copyFromDayPopupVisible, setCopyFromDayPopupVisible] = useState(false);
    const [findReplacePopupVisible, setFindReplacePopupVisible] = useState(false);
    const [selectedDietVariation, setDietVariation] = useState('DietVariation1');
    let dataManager = new DataManager({
        url: `${process.env.REACT_APP_URL}/api/odata/DietPlanItem`,
        adaptor: new ODataV4Adaptor(),
        headers: [{ 'Authorization': `Bearer ${localStorage.getItem("token")}` }],
        crossDomain: true,
    });
    let kanbanObj = useRef(null);
    let findAndReplaceObject = useRef(null);
    let copyClientDietObject = useRef(null);

    let predicate = new Predicate('PersonalFile/Oid', 'equal', props.activeDossierOid);
    predicate = predicate.and('DietVariation', 'equal', selectedDietVariation);
    const dataQuery = new Query().expand('PersonalFile').where(predicate);
    function sortBy(props) {
        return props.sort(compareByEatMoment);
    }
    let sorting = sortBy;

    function compareByEatMoment(a, b) {
        return a.keyField - b.keyField;
    }
    function actionBegin(args) {
        if (args.requestType === "cardCreate")
        {
            delete args.addedRecords[0].undefined;
            delete args.addedRecords[0].Oid;
            delete args.addedRecords[0].DayOfWeekString;
            args.addedRecords[0]['PersonalFileOid'] = props.activeDossierOid;
            args.addedRecords[0]['DietVariation'] = selectedDietVariation;
        }
        else if (args.requestType === 'cardCreated') {
        }
        else if (args.requestType === "cardChange") {
        }
        else if (args.requestType === "cardRemove") {
        }
    }
    
    const actionComplete = useCallback((args) => {
        if (args.requestType === 'cardCreated') {
            //kanbanObj.refresh();
        }
        else if (args.requestType === 'cardRemoved') {
        }
    }, []);

    const onDownloadPDFClick = useCallback((e) => {
        getDietPlanDocument(props.activeDossierOid, selectedDietVariation).then((res) => {
            const file = new Blob(
                [res.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            var fileName = res.headers['content-disposition'].replace("inline; filename=", "");
            fileLink.download = fileName;
            //fileLink.click();
            window.open(fileLink.href);
        }).catch((e) => {
            if (props.activeDossierOid == null) { notify("No active dossier found! Save first", 'error', 2000); return }
            notify(e.message, 'error', 2000);
        });
    }, [props.activeDossierOid, selectedDietVariation]);

    const onOpenCopyFromClientModal = () => {
        if (copyClientDietPopupVisible)
        {
            setCopyClientDietPopupVisible(false);
        }
        setCopyClientDietPopupVisible(true);
    }

    const onOpenCopyFromDayModal = () => {
        if (copyFromDayPopupVisible) {
            setCopyFromDayPopupVisible(false);
        }
        setCopyFromDayPopupVisible(true);
    }

    const onOpenFindReplaceModal = () => {
        if (findReplacePopupVisible) {
            setFindReplacePopupVisible(false);
        }
        setFindReplacePopupVisible(true);
    }

    const hideInfo = () => {
        setCopyClientDietPopupVisible(false);
        setCopyFromDayPopupVisible(false);
        setFindReplacePopupVisible(false);
        if (findAndReplaceObject.current) {
            findAndReplaceObject.current.clearForm();
        }
        if (copyClientDietObject.current) {
            copyClientDietObject.current.clearForm();
        } 
    }

    const closeButtonOptions = {
        text: formatMessage('Close'),
        onClick: hideInfo
    };

    const selectedDietVariationhanged = useCallback((e) => {
        setDietVariation(e.value.Value);
    }, [selectedDietVariation]);

    function dialogTemplate(props) {
        return (<KanbanDialogFormTemplate {...props} />);
    }
    
    function findIndex(rowElement) {
        let rowElementList = document.querySelectorAll(".e-kanban-table tbody .e-content-row");
        let foundIndex = Array.from(rowElementList).findIndex((element) => element.isEqualNode(rowElement));
        return rowElementList[foundIndex - 1].getAttribute("data-key");
    }

    function cardTemplate(props) {
        return (<div className="card-template">
            <div className="e-card-header">
                <div className="e-card-header-caption">
                    <div className="e-card-header-title">{props.ItemName}
                    </div>
                </div>
            </div>
            <div className="e-card-content">{props.ItemQuantity}</div>
        </div>);
    }

    let getCopiedCardData;
    const created = (args) => {
        const board = document.querySelector('#kanban.kanban-swimlane');
        board.addEventListener('keydown', (event) => {
            if (event.ctrlKey && event.key === 'c') {
                let getCopiedCardElement = kanbanObj.current.getSelectedCards()[0];

                if (getCopiedCardElement) {
                    getCopiedCardData = kanbanObj.current.getCardDetails(getCopiedCardElement);
                    event.preventDefault();
                } else { notify(formatMessage('NoTaskSelected'), 'error', 2000); }
            }
           
            if (event.ctrlKey && event.key === 'v') {
                // Monday
                if (event.target.closest(".e-content-cells[data-key='Monday']")) {
                    if (event.target.getAttribute('data-key') === 'Monday' != undefined || null) {
                        let rowElement = event.target.closest(".e-content-cells[data-key='Monday']").closest(".e-content-row");
                        let simlaneKeyfild = findIndex(rowElement);
                        getCopiedCardData.EatMomentInt = simlaneKeyfild;
                        getCopiedCardData.EatMoment = EatMoments[simlaneKeyfild].Name;
                        getCopiedCardData.DayOfWeek = 1;
                        getCopiedCardData.DayOfWeekString = "Monday";
                        getCopiedCardData.DietVariation = selectedDietVariation;
                        kanbanObj.current.addCard(getCopiedCardData, 1);
                    }
                }
                // Tuesday
                if (event.target.closest(".e-content-cells[data-key='Tuesday']") ) {
                    if (event.target.getAttribute('data-key') === 'Tuesday' != undefined || null) {
                        let rowElement = event.target.closest(".e-content-cells[data-key='Tuesday']").closest(".e-content-row");
                        let simlaneKeyfild = findIndex(rowElement);
                        getCopiedCardData.EatMomentInt = simlaneKeyfild;
                        getCopiedCardData.EatMoment = EatMoments[simlaneKeyfild].Name;
                        getCopiedCardData.DayOfWeek = 2;
                        getCopiedCardData.DayOfWeekString = "Tuesday";
                        getCopiedCardData.DietVariation = selectedDietVariation;
                        kanbanObj.current.addCard(getCopiedCardData, 2);
                    }
                }
                // Wednesday
                if (event.target.closest(".e-content-cells[data-key='Wednesday']") !== undefined || null) {
                    if (event.target.getAttribute('data-key') === 'Wednesday') {
                        let rowElement = event.target.closest(".e-content-cells[data-key='Wednesday']").closest(".e-content-row");
                        let simlaneKeyfild = findIndex(rowElement);
                        getCopiedCardData.EatMomentInt = simlaneKeyfild;
                        getCopiedCardData.EatMoment = EatMoments[simlaneKeyfild].Name;
                        getCopiedCardData.DayOfWeek = 3;
                        getCopiedCardData.DayOfWeekString = "Wednesday";
                        getCopiedCardData.DietVariation = selectedDietVariation;
                        kanbanObj.current.addCard(getCopiedCardData, 3);
                    }
                }
                // Thursday
                if (event.target.closest(".e-content-cells[data-key='Thursday']") !== undefined || null) {
                    if (event.target.getAttribute('data-key') === 'Thursday') {
                        let rowElement = event.target.closest(".e-content-cells[data-key='Thursday']").closest(".e-content-row");
                        let simlaneKeyfild = findIndex(rowElement);
                        getCopiedCardData.EatMomentInt = simlaneKeyfild;
                        getCopiedCardData.EatMoment = EatMoments[simlaneKeyfild].Name;
                        getCopiedCardData.DayOfWeek = 4; 
                        getCopiedCardData.DayOfWeekString = "Thursday";
                        getCopiedCardData.DietVariation = selectedDietVariation;
                        kanbanObj.current.addCard(getCopiedCardData, 4);
                    }
                }

                // Friday
                if (event.target.closest(".e-content-cells[data-key='Friday']") !== undefined || null) {
                    if (event.target.getAttribute('data-key') === 'Friday') {
                        let rowElement = event.target.closest(".e-content-cells[data-key='Friday']").closest(".e-content-row");
                        let simlaneKeyfild = findIndex(rowElement);
                        getCopiedCardData.EatMoment = EatMoments[simlaneKeyfild].Name;
                        getCopiedCardData.DayOfWeek = 5;
                        getCopiedCardData.DayOfWeekString = "Friday";
                        getCopiedCardData.DietVariation = selectedDietVariation;
                        kanbanObj.current.addCard(getCopiedCardData, 5);
                    }
                }
                // Saturday
                if (event.target.closest(".e-content-cells[data-key='Saturday']") !== undefined || null) {
                    if (event.target.getAttribute('data-key') === 'Saturday') {
                        let rowElement = event.target.closest(".e-content-cells[data-key='Saturday']").closest(".e-content-row");
                        let simlaneKeyfild = findIndex(rowElement);
                        getCopiedCardData.EatMomentInt = simlaneKeyfild;
                        getCopiedCardData.EatMoment = EatMoments[simlaneKeyfild].Name;
                        getCopiedCardData.DayOfWeek = 6;
                        getCopiedCardData.DayOfWeekString = "Saturday";
                        getCopiedCardData.DietVariation = selectedDietVariation;
                        kanbanObj.current.addCard(getCopiedCardData, 6);
                    }
                }

                // Sunday
                if (event.target.closest(".e-content-cells[data-key='Sunday']") !== undefined || null) {
             
                    if (event.target.getAttribute('data-key') === 'Sunday') {
                        let rowElement = event.target.closest(".e-content-cells[data-key='Sunday']").closest(".e-content-row");
                        let simlaneKeyfild = findIndex(rowElement);
                        getCopiedCardData.EatMomentInt = simlaneKeyfild;
                        getCopiedCardData.EatMoment = EatMoments[simlaneKeyfild].Name;
                        getCopiedCardData.DayOfWeek = 7;
                        getCopiedCardData.DayOfWeekString = "Sunday";
                        getCopiedCardData.DietVariation = selectedDietVariation;
                        kanbanObj.current.addCard(getCopiedCardData, 7);
                    }
                }
            };
        });
    };

    return (<div className='kanban-control-section margin'>
        <div className='col-lg-8 control-section'>
            <div className='control-wrapper '>
                <Popup
                    width={660}
                    height={540}
                    showTitle={true}
                    title={formatMessage('CopyDayToDays')}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    visible={copyFromDayPopupVisible}
                    options={closeButtonOptions}>
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="top"
                        location="after"
                        options={closeButtonOptions} />
                    <div>
                        <SelectDaysForm onComplete={hideInfo} dietVariation={selectedDietVariation} clientPersonalFileOid={props.activeDossierOid} />
                    </div>
                </Popup>
                <Popup
                    width={660}
                    height={540}
                    showTitle={true}
                    title={formatMessage('CopyDietToClient')}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    visible={copyClientDietPopupVisible}
                    options={closeButtonOptions}>
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="top"
                        location="after"
                        options={closeButtonOptions} />
                    <div>
                        <CopyClientDietDialog ref={copyClientDietObject} onComplete={hideInfo} clientPersonalFileOid={props.activeDossierOid} dietVariation={selectedDietVariation} />
                    </div>
                </Popup>
                <Popup
                    width={660}
                    height={540}
                    showTitle={true}
                    title={formatMessage('FindAndReplace')}
                    dragEnabled={false}
                    hideOnOutsideClick={true}
                    visible={findReplacePopupVisible}
                    options={closeButtonOptions}>
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="top"
                        location="after"
                        options={closeButtonOptions} />
                    <div>
                        <FindReplaceDialog ref={findAndReplaceObject} onComplete={hideInfo} clientPersonalFileOid={props.activeDossierOid} dietVariation={selectedDietVariation} />
                    </div>
                </Popup>
                <Toolbar className='kanban-control-section marginToolbar'>
                    <Item location="before">
                        <Button
                            text={formatMessage('CopyDayToDays')}
                            type="success"
                            onClick={onOpenCopyFromDayModal} />
                    </Item>
                    <Item location="before">
                        <Button
                            text={formatMessage('CopyDietToClient')}
                            type="success"
                            onClick={onOpenCopyFromClientModal} />
                    </Item>
                    <Item location="before">
                        <Button
                            text='Find & Replace'
                            type="success"
                            onClick={onOpenFindReplaceModal} />
                    </Item>
                    <Item location="before">
                        <Button
                            text='Download PDF'
                            type="success"
                            onClick={onDownloadPDFClick} />
                    </Item>
                    <Item location="after">
                        <SelectBox
                            width="225"
                            displayExpr="Name" 
                            items={DietVariation}
                            defaultValue={DietVariation[0]}
                            onValueChanged={selectedDietVariationhanged} />
                    </Item>
                </Toolbar>
                <KanbanComponent
                    id="kanban"
                    ref={kanbanObj}
                    enablePersistence="true"
                    cssClass="kanban-swimlane"
                    keyField="DayOfWeekString"
                    dataSource={dataManager}
                    query={dataQuery}
                    actionBegin={actionBegin}
                    actionComplete={actionComplete}
                    swimlaneSettings={{ keyField: "EatMomentInt", sortComparer: sorting.bind(this), sortDirection: 'Ascending', textField: 'EatMoment', showEmptyRow: true, allowDragAndDrop: true }}
                    dialogSettings={{ template: dialogTemplate.bind(this) }}
                    created={created}
                    cardSettings={{ contentField: "ItemQuantity", headerField: "Oid", template: cardTemplate.bind(this), selectionType: "Single" }}>
                    <ColumnsDirective >
                        <ColumnDirective headerText={formatMessage('Monday')} keyField="Monday" showAddButton="true" />
                        <ColumnDirective headerText={formatMessage('Tuesday')} keyField="Tuesday" showAddButton="true" />
                        <ColumnDirective headerText={formatMessage('Wednesday')} keyField="Wednesday" showAddButton="true" />
                        <ColumnDirective headerText={formatMessage('Thursday')} keyField="Thursday" showAddButton="true" />
                        <ColumnDirective headerText={formatMessage('Friday')} keyField="Friday" showAddButton="true" />
                        <ColumnDirective headerText={formatMessage('Saturday')} keyField="Saturday" showAddButton="true" />
                        <ColumnDirective headerText={formatMessage('Sunday')} keyField="Sunday" showAddButton="true" />
                    </ColumnsDirective>
                </KanbanComponent>
            </div>
        </div>
    </div>);
}
export default Swimlane;

function KanbanDialogFormTemplate(props) {
    const [state, setState] = useState(updateProps(props));
    function updateProps(props) {
        let obj = Object.assign({}, props);
        delete obj['undefined'];
        obj['DayOfWeek'] = days.find(day => day.Name === obj.DayOfWeekString).Value;
        if (!obj.hasOwnProperty('ItemQuantity'))
        {
            obj['ItemQuantity'] = "";
        }
        if (!obj.hasOwnProperty('ItemName')) {
            obj['ItemName'] = "";
        }

        return obj;
    };

    function onChange(args) {
        let key = args.target.name;
        let value = args.target.value;
        setState({ [key]: value });
    }
    const fields = { text: 'Name', value: 'Value' };
    let data = state;
    return (<div>
        <table>
            <tbody>
                <tr>
                    <td className="e-label">{formatMessage('Day')}</td>
                    <td>
                        <DropDownListComponent id='DayOfWeek' name="DayOfWeek" dataSource={days} fields={fields} className="e-field" placeholder='DayOfWeek' value={data.DayOfWeek}></DropDownListComponent>
                    </td>
                </tr>
                <tr>
                    <td className="e-label">{formatMessage('EatMoment')}</td>
                    <td>
                        <DropDownListComponent id='EatMoment' name="EatMoment" dataSource={EatMoments} fields={fields} className="e-field" placeholder='EatMoment' value={parseInt(data.EatMomentInt)}></DropDownListComponent>
                    </td>
                </tr>
                <tr>
                    <td className="e-label">{formatMessage('FullName')}</td>
                    <td>
                        <div className="e-float-input e-control-wrapper">
                            <input type="text" name="ItemName" className="e-field" value={data.ItemName} onChange={onChange.bind(this)}></input>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td className="e-label">{formatMessage('Quantity')}</td>
                    <td>
                        <div className="e-float-input e-control-wrapper">
                            <input type="text" name="ItemQuantity" className="e-field" value={data.ItemQuantity} onChange={onChange.bind(this)}></input>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>);
}