import React, { useState, useEffect } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Button from 'devextreme-react/button';
import UserPanel from '../user-panel/UserPanel';
import './Header.scss';
import { useAuth } from '../../contexts/auth';
import { Template } from 'devextreme-react/core/template';
import logo from "../../resources/Images/logo.png";
import nl from "../../resources/Images/netherlands.png";
import en from "../../resources/Images/united-states.png";
import { Popover } from 'devextreme-react/popover';
import List from 'devextreme-react/list';
import DataSource from 'devextreme/data/data_source';
import NotificationItem from './NotificationItem';
import ODataStore from 'devextreme/data/odata/store';
export default function Header({ menuToggleEnabled, title, toggleMenu }) {
    const { user } = useAuth();
    const [ activeNotificationCount, setActiveNotificationCount ] = useState(0);
    const odataStore = new ODataStore({
        type: 'odata',
        key: 'Oid',
        keyType: 'Guid',
        paginate: false,
        url: `${process.env.REACT_APP_URL}/api/odata/Notification`,
        version: 4,
        beforeSend: function (e) {
            e.headers = { 'Authorization': `Bearer ${localStorage.getItem("token")}` };
        },
        onLoaded: function (result) {
            const activeNotifications = result.filter((d) => d.Seen === false).length;
            setActiveNotificationCount(activeNotifications);
        },
        onUpdating: function (key, values) {
        }
    });
    const notificationDataSource = new DataSource({
        store: odataStore ,
        sort: ['CreatedAt']
    });

    const setLanguage = (language) => {
        localStorage.setItem('locale', language);
        document.location.reload();
    }

    const onItemClick = (e) =>
    {
        if (e.itemData && e.itemData.Seen === false) {
            odataStore.update(e.itemData.Oid, { Seen: true }).then(
                (dataObj) => { notificationDataSource.load(); },
                (error) => {  });
        }
    }

  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item
          visible={menuToggleEnabled}
          location={'before'}
          widget={'dxButton'}
          cssClass={'menu-button'} >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
              <Item
                  location={'before'}
                  cssClass={'header-title'}>
                  <img width="150px" height="30px" src={logo} alt="Logo" />
              </Item>
        <Item
            location={'after'}
                  widget={'dxButton'} >
                  {activeNotificationCount > 0 ? (
                      <div className='messages'>
                          <Button id="notification" stylingMode="icon" icon="bell" />
                          <div className='dx-badge'>
                              {activeNotificationCount}
                          </div>
                      </div>) :
                      (
                         <Button id="notification" stylingMode="icon" icon="bell" />
                      )}
                  <Popover
                      target="#notification"
                      showEvent="click"
                      position={ {my: 'top center', at: 'bottom left', collision: 'fit flip' }}
                      width={400}>
                      <div><b>Meldingen</b></div>
                      <List
                          onItemClick={onItemClick}
                          dataSource={notificationDataSource}
                          itemRender={NotificationItem}
                          title="Meldingen"
                          height={400} />
                  </Popover>
        </Item>
        <Item
          location={'after'}
          locateInMenu={'auto'}
          menuItemTemplate={'userPanelTemplate'} >
          <Button
            className={'user-button authorization'}
            width={200}
            height={'100%'}
            stylingMode={'text'} >
            <UserPanel menuMode={'context'} />
          </Button>
              </Item>
              <Item              
                  location={'after'}
                  widget={'dxButton'} >
                  <Button stylingMode="image" icon={nl} onClick={() => setLanguage('nl')} />
              </Item>
              <Item
                  location={'after'}
                  widget={'dxButton'} >
                  <Button stylingMode="image" icon={en} onClick={() => setLanguage('en')} />
              </Item>
        <Template name={'userPanelTemplate'}>
          <UserPanel menuMode={'list'} />
        </Template>
      </Toolbar>
    </header>
)}
