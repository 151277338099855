import { formatMessage } from 'devextreme/localization';
export const days = [
    {
        Name: 'Monday',
        Value: 1
    },
    {
        Name: 'Tuesday',
        Value: 2
    },
    {
        Name: 'Wednesday',
        Value: 3
    },
    {
        Name: 'Thursday',
        Value: 4
    },
    {
        Name: 'Friday',
        Value: 5
    },
    {
        Name: 'Saturday',
        Value: 6
    },
    {
        Name: 'Sunday',
        Value: 7
    }
];
export const DieetPeriods = [
    {
        Name: "1 Weeks",
        Value: 'Weeks1'
    },
    {
        Name: "4 Weeks",
        Value: 'Weeks4'
    },
    {
        Name: "6 Weeks",
        Value: 'Weeks6'
    },
    {
        Name: "8 Weeks",
        Value: 'Weeks8'
    },
    {
        Name: "10 Weeks",
        Value: 'Weeks10'
    },
    {
        Name: "12 Weeks",
        Value: 'Weeks12'
    },
    {
        Name: "14 Weeks",
        Value: 'Weeks14'
    },
    {
        Name: "16 Weeks",
        Value: 'Weeks16'
    },
    {
        Name: "18 Weeks",
        Value: 'Weeks18'
    },
    {
        Name: "20 Weeks",
        Value: 'Weeks20'
    },
    {
        Name: "24 Weeks",
        Value: 'Weeks24'
    }
];
export const DietVariation = [
    {
        Name: "Diet variation 1",
        Value: "DietVariation1"
    },
    {
        Name: "Diet variation 2",
        Value: "DietVariation2"
    },
    {
        Name: "Diet variation 3",
        Value: "DietVariation3"
    },
    {
        Name: "Diet variation 4",
        Value: "DietVariation4"
    }
];
export const EatMoments = [
    {
        Name: "Breakfast",
        Value: 0,

    },
    {
        Name: "FirstBreak",
        Value: 1,
    },
    {
        Name: "Lunch",
        Value: 2,
    },
    {
        Name: "SecondBreak",
        Value: 3,
    },
    {
        Name: "Diner",
        Value: 4,
    },
    {
        Name: "ThirdBreak",
        Value: 5,
    },
];

export const TaskPriority = [
    { Name: "Low", Value: 0 },
    { Name: "Normal", Value: 1 },
    { Name: "High", Value: 2 }
];

export const TaskStatus = [
    { Name: "Open", Value: 0 },
    { Name: "InProgress", Value: 1 },
    { Name: "Completed", Value: 2 },
    { Name: "Cancelled", Value: 3 }
];