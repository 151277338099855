import axios from 'axios';

export async function uploadJson(weekNumber, fileName, companyName, data) {
    const res = await axios.post(`/api/EmailNewsletter/UploadJson?weekNumber=${weekNumber}&fileName=${fileName}&companyName=${companyName}`, String(JSON.stringify(data)), await GetHeader());

    try {
        return res;
    }
    catch {
        return res;
    }
}

export async function uploadHtml(weekNumber, fileName, companyName, data) {
    const res = await axios.post(`/api/EmailNewsletter/UploadEmail?weekNumber=${weekNumber}&fileName=${fileName}&companyName=${companyName}`, data, await GetHeader());

    try {
        return res;
    }
    catch {
        return res;
    }
}

export async function getJsonEmailContent(weekNumber, fileName, companyName) {
    const res = await axios.get(`/api/EmailNewsletter/GetJson?weekNumber=${weekNumber}&fileName=${fileName}&companyName=${companyName}`, await GetHeader());
    try {
        return res;
    }
    catch {
        return res;
    }
}

export async function subscribeNewsletter(email, firstName, lastName) {
    let jsonObject =
    {
        email: email,
        firstName: firstName,
        lastName: lastName,
    }
    const res = await axios.post(`/api/EmailNewsletter/SubscribeNewsletter`, jsonObject, await GetHeader());
    try {
        return res;
    }
    catch {
        return res;
    }
}
export async function unsubscribeNewsletter(email) {
    const res = await axios.post(`/api/EmailNewsletter/UnsubscribeNewsletter`, email, await GetHeader());
    try {
        return res;
    }
    catch {
        return res;
    }
}

export async function reSendNewsletter(email, weekNumber) {
    const res = await axios.post(`/api/EmailNewsletter/SendNewsletter?email=${email}&weekNumberLong=${weekNumber}`, null, await GetHeader());
    try {
        return res;
    }
    catch {
        return res;
    }
}

export async function reSendNewsletters(weekNumber) {
    const res = await axios.post(`/api/EmailNewsletter/ResendNewsletters?weekNumberLong=${weekNumber}`, null, await GetHeader());
    try {
        return res;
    }
    catch {
        return res;
    }
}

export async function saveNewsletterCampaignFormRequest(data) {
    const res = await axios.post(`/api/odata/NewsletterCampaign`, data, await GetHeader());
    try {
        return res;
    }
    catch {
        return res;
    }
}

async function GetHeader() {
    const header = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
    }
    return header;
}