import React, { useState, useEffect, useCallback, useRef, forwardRef, useImperativeHandle } from 'react';
import { Lookup, DropDownOptions } from 'devextreme-react/lookup';
import { Button } from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import { copyClientDiet } from '../../api/dietItemPlan';
import SelectBox from 'devextreme-react/select-box';
import { DietVariation } from '../../Enums';
const CopyClientDietDialog = forwardRef(({ clientPersonalFileOid, dietVariation, onComplete}, ref) => {
    const [selectedPerson, setPerson] = useState(null);
    const { user, signOut } = useAuth();
    const [fromDietVariation, setDietVariation] = useState('DietVariation1');
    const dietVariationSelectBox = useRef(null);
    const relationData = {
        store: {
            type: 'odata',
            version: 4,
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/Relation`,
            errorHandler: (error) => {
                if (error.httpStatus === 401) {
                    signOut(true);
                }
            },
            beforeSend: function (e) {
                e.headers = {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                };

            },
        },
        select: [
            'Oid',
            'FullName',
            'ActivePersonalFile'
        ],
        expand: ['ActivePersonalFile(expand=PersonalGoal,NutritionCategory)'],
        filter: ['ActivePersonalFile', '<>', null],
        sort: [
            { getter: "FirstName", desc: false }
        ]
    };

    useImperativeHandle(ref, () => ({
        clearForm: () => clearForm(),
        onComplete: onComplete
    }));

    const clearForm = () => {
        setPerson(null);
        setDietVariation('DietVariation1');
        dietVariationSelectBox.current.instance.reset();
    };

    const selectedPersonChanged = useCallback((e) => {
        setPerson(e.value);
    }, []);

    const onSubmit = async (e)  => {
        if (selectedPerson != null) {
            await copyClientDiet(selectedPerson.ActivePersonalFile.Oid, clientPersonalFileOid, fromDietVariation, dietVariation).then((result) => {
                if (result.status === 201) {
                    notify(result.data, 'success', 2000);
                    onComplete();
                }

            }).catch(error => {
                notify(error.response.data, 'error', 2000);
            });
        } else
        {
            notify("Select an client first...", 'error', 2000);
        }
    };

    const selectedDietVariationhanged = useCallback((e) => {
        setDietVariation(e.value.Value);
    }, [fromDietVariation]);

    return (
        <React.Fragment>
            <div className={'content-block'}>
                <div style={{ paddingBottom: 10 }} >
                    <SelectBox
                        ref={dietVariationSelectBox}
                        width="225"
                        displayExpr="Name"
                        items={DietVariation}
                        defaultValue={DietVariation[0]}
                        onValueChanged={selectedDietVariationhanged} />
                </div>
                <Lookup
                    value={selectedPerson}
                    searchEnabled={true}
                    dataSource={relationData}
                    onValueChanged={selectedPersonChanged}
                    applyValueMode="instantly"
                    searchExpr="FullName"
                    displayExpr="FullName" />
                <div>
                    {
                        (selectedPerson == null) ? (
                            "Select an client first..."
                        ) :
                            (
                                <div style={{ position: 'absolute',padding: 10 }} >
                                    <div>Goal: {selectedPerson.ActivePersonalFile.PersonalGoal.Name}</div>
                                    <div>Category: {selectedPerson.ActivePersonalFile.NutritionCategory.Name}</div>
                                </div>

                                )
                    }

                </div>

                <div style={{ position: 'absolute', bottom: 0, right: 0, padding: 10}}>
                    <Button
                        icon="save"
                        text='Apply'
                        onClick={onSubmit}
                        type="success" />
                </div>
            </div>
         
        </React.Fragment>
    );
});
export default CopyClientDietDialog;
