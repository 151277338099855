import axios from 'axios';
export async function getRecipeDocument(recipeOid) {
    const res = await axios.get(`/api/RecepiePdf/GetRecipeDocument?RecipeOid=${recipeOid}`, await GetHeaderBlob());
    try {
        return res;
    }
    catch {
        return res;
    }
}

async function GetHeaderBlob() {
    const headerBlob = {
        responseType: "blob",
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
    };
    return headerBlob;
}

