import React, { useEffect, useState, useCallback, useRef } from 'react';
import './home.scss';
import { Button } from 'devextreme-react/button';
import Toolbar, { Item as ToolbarItem } from 'devextreme-react/toolbar';
import Tabs, { Item } from 'devextreme-react/tab-panel';
import Scheduler from 'devextreme-react/scheduler';
import { useAuth } from '../../contexts/auth';
import CalendlyService from '../../api/calendlyService';
import Appointment from './appointment';
import { LoadPanel } from 'devextreme-react/load-panel';
import { ActivityList } from '../../components';
import notify from 'devextreme/ui/notify';
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import { useCalendlyEventListener, PopupModal } from "react-calendly";
export default function Home() {
    const { user, signOut, getCalendlyToken } = useAuth();
    const views = ['week', 'month'];
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openAddEventModal, setOpenAddEventModal] = useState(false);
    const [activityCount, setActivityCount] = useState(0);
    const activityDataSource = new DataSource({
        store: {
            type: 'odata',
            key: 'Oid',
            keyType: 'Guid',
            paginate: false,
            url: `${process.env.REACT_APP_URL}/api/odata/Activity`,
            version: 4,
            errorHandler: (error) => {
                if (error.httpStatus === 401) {
                    signOut(true);
                }
            },
            beforeSend: function (e) {
                e.headers = { 'Authorization': `Bearer ${localStorage.getItem("token")}` };
            },
            onLoaded: function (result) {
                const openActivitys = result.filter((d) => d.Status === 0 || d.Status === 1).length;
                setActivityCount(openActivitys);
            },
            onInserting: function (values, key) {
                if (values.Relation) {
                    values["RelationOid"] = values.Relation.Oid.toString();
                    delete values.Relation;
                }
            },
            onUpdating: function (key, values) {
                if (values.Relation) {
                    values["Relation"] = values.Relation.Oid.toString();
                }
            }
        },
        expand: ['Relation']
    });

    const fetchData = async () => {
        try {
            const params = new URLSearchParams(window.location.search);
            const code = params.get('code');
            if (code) {
                await getCalendlyToken(code);
            }

            const calendlyService = new CalendlyService(user.calendly.access_token, user.calendly.refresh_token);
            const userEvents = await calendlyService.getUserScheduledEvents(user.calendly.owner);
            const updatedEvents = await Promise.all(userEvents.map(async (item) => {
                const eventId = item.uri.replace("https://api.calendly.com/scheduled_events/", "");
                const invite = await calendlyService.getUserScheduledEventInvitees(eventId);
                item["invite"] = invite.collection[0];
                return item;
            }));

            setEvents(updatedEvents);
            setLoading(false);
        } catch (error) {
            notify('Error fetching data', "error", 200);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
        activityDataSource.load();
    }, [getCalendlyToken, user.calendly.access_token, user.calendly.refresh_token, user.calendly.owner]);

    const onOpenAddEventModal = () => {
        if (openAddEventModal) {
            setOpenAddEventModal(false);
        } else
        {
            setOpenAddEventModal(true);
        }
    }

    const onContentReady = useCallback(
        (e) =>
        {
            setLoading(true);
            e.component.scrollTo(new Date());
            setTimeout(() => {
                console.log('Delayed execution');
            }, 2000);
            setLoading(false);
        });

    useCalendlyEventListener({
        onEventScheduled: (e) =>
        {
            fetchData();
            setOpenAddEventModal(false);
        }
    });

    return (
        <React.Fragment>
            {loading ? (
                <div id="home" className={'content-block'}>
                <div>
                        <LoadPanel
                            shadingColor="rgba(0,0,0,0.4)"
                            visible={loading}
                            showIndicator={true}
                            shading={true}
                            showPane={true}
                        />
                    </div>
                </div>
            ) : (
                <div className={'content-block'}>
                        <Tabs>
                            <Item title="Agenda">
                            <div>
                               <Toolbar>
                                    <ToolbarItem location="after">
                                        <Button
                                            icon="plus"
                                                type="success"
                                                onClick={onOpenAddEventModal}
                                         />
                                    </ToolbarItem>
                                    </Toolbar>
                                    <PopupModal
                                        url="https://calendly.com/chooseyourdiet"
                                        onModalClose={onOpenAddEventModal}
                                        open={openAddEventModal}
                                        rootElement={document.getElementById("root")}
                                    />
                                <Scheduler
                                timeZone="Europe/Amsterdam"
                                dataSource={events}
                                views={views}
                                defaultCurrentView="week"
                                height={'800'}
                                startDayHour={7}
                                cellDuration={30}
                                textExpr="name"
                                startDateExpr="start_time"
                                endDateExpr="end_time"
                                appointmentComponent={Appointment}
                                onContentReady={onContentReady}
                                    />
                                </div>
                        </Item>
                            <Item title="Taken" badge={activityCount}>
                                <ActivityList dataSource={activityDataSource} mode="cell" displayRelation />
                            </Item>
                    </Tabs>
                </div>
            )}
        </React.Fragment>
    );
}
