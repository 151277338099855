import axios from 'axios';

export async function saveIntakeFormRequest(data, relationOid)
{
    data['RelationOid'] = relationOid;
    const res = await axios.post(`/api/odata/IntakeForm`, data, await GetHeader());
    try {
        return res;
    }
    catch {
        return res;
    }
}

export async function sendWeeklyProgressForm(data, personalFileOid, weekNumber) {
    data['PersonalFileOid'] = personalFileOid;
    data['WeekNumber'] = weekNumber;
    console.log(data);
    const res = await axios.post(`/api/WeeklyProgressForm/InsertWeeklyProgressForm`, data, await GetHeader());
    try {
        return res;
    }
    catch {
        return res;
    }
}

export async function getIntakeFormRequest(relationOid) {
    const res = await axios.get(`/api/odata/IntakeForm?$filter=RelationOid eq '${relationOid}'`, await GetHeader());
    try {
        return res;
    }
    catch {
        return res;
    }
}

export async function getIntakeFormDocument(intakeFormOid) {
    const res = await axios.get(`/api/IntakeForm/GetIntakeFormDocument?IntakeFormOid=${intakeFormOid}`, await GetHeaderBlob());
    try {
        return res;
    }
    catch {
        return res;
    }
}


export async function updateIntakeFormRequest(data) {
    const res = await axios.put(`/api/odata/IntakeForm(${data.Oid})`, data, await GetHeader());
    try {
        return res;
    }
    catch {
        return res;
    }
}

async function GetHeader() {
    const header = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
    }
    return header;
}

async function GetHeaderBlob() {
    const headerBlob = {
        responseType: "blob",
        headers: {
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
    };
    return headerBlob;
}