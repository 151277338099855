import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import appInfo from './app-info';
import routes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';
import { useAuth } from './contexts/auth';

export default function Content() {
    const [currRoutes, setCurrRoutes] = useState(routes);
    const { user,getUserRole } = useAuth();

    useEffect(() => {
        (async function () {
            const result = await getUserRole();
            const newRoutes = routes.filter(r => r.allowedUserTypes.includes(result));
            setCurrRoutes(newRoutes);
        })();

    }, [user])

    return (
        <SideNavBarLayout title={appInfo.title}>
            <Routes>
                {currRoutes.map(({ path, element }) => (
                    <Route
                        key={path}
                        path={path}
                        element={element}
                    />
                ))}
                <Route
                    path='*'
                    element={currRoutes.length > 0 ? <Navigate to={currRoutes[0].path} /> : <Navigate to="/" />}
                />

            </Routes>
            <Footer>
                Copyright © 2011-{new Date().getFullYear()} {appInfo.title} Inc.
                <br />
                All trademarks or registered trademarks are property of their
                respective owners.
            </Footer>
        </SideNavBarLayout>
    );
}
