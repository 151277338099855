
import React, { useState, useEffect, useCallback } from 'react';
import { Button } from 'devextreme-react/button';
import RadioGroup from 'devextreme-react/radio-group';
import { CheckBox } from 'devextreme-react/check-box';
import SelectBox from 'devextreme-react/select-box';
import notify from 'devextreme/ui/notify';
import { postCopyDietPlanRequest} from '../../api/dietItemPlan';
import { days, DietVariation } from '../../Enums';
import './selectDaysForm.css';
export default function SelectDaysForm(props, onComplete) {
    const [fromDay, setFromDay] = useState(1);
    const [toDays, setToDays] = useState(['1','2','3','4','5','6','7']);
    const [fromDietVariation, setDietVariation] = useState('DietVariation1');
    useEffect(() => {
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();
        await postCopyDietPlanRequest(props.clientPersonalFileOid._value, fromDay, toDays, fromDietVariation, props.dietVariation).then((result) => {
            notify(result.data, 'success', 2000);
            setToDays(['1', '2', '3', '4', '5', '6', '7']);
            props.onComplete();
        }).catch(error => {
            notify(error.response.data, 'error', 2000);
        });
    };

    const formDataChanged = (e) => {
        setFromDay(e.value)
    }

    const onValueChanged = (e) => {
        var day = String(e.element.$V.props.name);
        if (e.value) {
            if (!toDays.includes(day)) {
                 const newArray = [...toDays, day];
                 setToDays(newArray);
            }
        }
        else if (e.value === false && toDays.includes(day))
        {
            const newArray = [...toDays.filter(oldDay => oldDay !== day)];
            setToDays(newArray);
       
        }
    }

    const selectedDietVariationhanged = useCallback((e) => {
        setDietVariation(e.value.Value);
    }, [fromDietVariation]);


    return (
        <React.Fragment>
            <form onSubmit={onSubmit}>
                <div className="form">
                    <SelectBox
                        width="225"
                        displayExpr="Name"
                        items={DietVariation}
                        defaultValue={DietVariation[0]}
                        onValueChanged={selectedDietVariationhanged} />
                    <div className="grid-container-element">
                        <div className="grid-child-element purple">
                            <RadioGroup
                            valueExpr="Value" displayExpr="Name"
                                onValueChanged={formDataChanged}
                                items={days} defaultValue={days[0].Value} />
                        </div>
                        <div className="grid-child-element green">
                            <div className="checkBox">
                                <CheckBox name="1" defaultValue={true} onValueChanged={onValueChanged} />
                            </div>
                            <div className="checkBox">
                                <CheckBox name="2" defaultValue={true} onValueChanged={onValueChanged} />
                            </div>
                            <div className="checkBox">
                                <CheckBox name="3" defaultValue={true} onValueChanged={onValueChanged} />
                            </div>
                            <div className="checkBox">
                                <CheckBox name="4" defaultValue={true} onValueChanged={onValueChanged} />
                            </div>
                            <div className="checkBox">
                                <CheckBox name="5" defaultValue={true} onValueChanged={onValueChanged} />
                            </div>
                            <div className="checkBox">
                                <CheckBox name="6" defaultValue={true} onValueChanged={onValueChanged} />
                            </div>
                            <div className="checkBox">
                                <CheckBox name="7" defaultValue={true} onValueChanged={onValueChanged} />
                            </div>
                        </div>
                        <div className="grid-child-element green">
                            <div className="checkBox">

                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ position: 'absolute', bottom: 0, right: 0, padding: 10 }}>
                    <Button
                        icon="save"
                        text='Apply'
                        useSubmitBehavior={true}
                        type="success" />

                </div>
            </form>
      
        </React.Fragment>
    );
}
