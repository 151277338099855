import React, { useState, useCallback, useRef, forwardRef, useImperativeHandle } from 'react';
import { Button } from 'devextreme-react/button';
import notify from 'devextreme/ui/notify';
import { findAndReplaceDietItem } from '../../api/dietItemPlan';
import {
    Validator,
    RequiredRule
} from 'devextreme-react/validator';
import TextBox from 'devextreme-react/text-box';
const FindReplaceDialog = forwardRef(({ clientPersonalFileOid, dietVariation , onComplete}, ref) => {
    const [findItem, setFindItem] = useState('');
    const [replaceItem, setReplaceItem] = useState('');
    const [replaceItemValue, setReplaceItemValue] = useState('');
    const findItemTxtBox = useRef(null);
    const replaceItemTxtBox = useRef(null);
    const replaceItemValuemTxtBox = useRef(null);

    const onSubmit = async (e) => {
        if (clientPersonalFileOid != null || dietVariation != null) {
            await findAndReplaceDietItem(clientPersonalFileOid, dietVariation, findItem, replaceItem, replaceItemValue).then((result) => {
                if (result.status === 201) {
                    notify(result.data, 'success', 2000);
                    onComplete();
                }

            }).catch(error => {
                notify(error.response.data, 'error', 2000);
            });
        }
        else {
            notify("A error occured!", 'error', 2000);
        }
    };

    const clearForm = () => {
        findItemTxtBox.current.instance.reset();
        replaceItemTxtBox.current.instance.reset();
        replaceItemValuemTxtBox.current.instance.reset();
        setFindItem('');
        setReplaceItem('');
        setReplaceItemValue('');
    };

    useImperativeHandle(ref, () => ({
        clearForm: () => clearForm(),
        onComplete: onComplete
    }));

    const onTextChanged = useCallback((e) => {
        if (e.element.id === "findItem") {
            setFindItem(e.value);
        }
        else if (e.element.id === "replaceItem") {
            setReplaceItem(e.value);
        }
        else if (e.element.id === "replaceItemValue") {
            setReplaceItemValue(e.value);
        }

    }, [findItem, replaceItem, replaceItemValue]);

    return (
        <React.Fragment>
            <div className={'content-block'}>
                <div style={{ paddingBottom: 10 }} >
                    <p>Find</p>
                    <TextBox
                        ref={findItemTxtBox}
                        id="findItem"
                        width="100%"
                        label="Find"
                        labelMode="hidden"
                        onValueChanged={onTextChanged} >
                        <Validator>
                            <RequiredRule message="This field is required" />
                        </Validator>
                    </TextBox>
                </div>
                <div style={{ paddingBottom: 10 }} >
                    <p>Replace</p>
                    <TextBox
                        ref={replaceItemTxtBox}
                        id="replaceItem"
                        width="100%"
                        label="Replace"
                        labelMode="hidden"
                        onValueChanged={onTextChanged} />
                </div>
                <div style={{ paddingBottom: 10 }} >
                    <p>Replace Value</p>
                    <TextBox
                        ref={replaceItemValuemTxtBox}
                        id="replaceItemValue"
                        width="100%"
                        label="Value"
                        labelMode="hidden"
                        onValueChanged={onTextChanged} />
                </div>
                <div style={{ position: 'absolute', bottom: 0, right: 0, padding: 10 }}>
                    <Button
                        icon="save"
                        text='Done'
                        onClick={onSubmit}
                        type="success" />
                </div>
            </div>
        </React.Fragment>
    );
});
export default FindReplaceDialog;