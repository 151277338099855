import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { formatMessage } from 'devextreme/localization';

export default function ActivePersonalFileDetails(props) {
    const [loading, setLoading] = useState(true);
    const [error] = useState(false);

    useEffect(() => {
        if (props.parentIsLoaded) {
            setLoading(false);
        }
    }, [props]);

    return loading ? (
        "Loading..."
    ) : error ? (
        "No active file found!"
    ) : (
        <React.Fragment>
            <div>
                <div>{formatMessage('CurrentWeek')}: {props.activeDossier && props.activeDossier.CurrentWeek}</div>
                <div>{formatMessage('Goal')}: {props.activeDossier && props.activeDossier.PersonalGoal && props.activeDossier.PersonalGoal.Name}</div>
                <div>{formatMessage('Category')}: {props.activeDossier && props.activeDossier.NutritionCategory && props.activeDossier.NutritionCategory.Name}</div>
                <div>
                    <strong>{formatMessage('ActiveDossier')}: {props.activeDossier && props.activeDossier.StartPeriod && moment(props.activeDossier.StartPeriod).format('DD-MM-YYYY')} {props.activeDossier && props.activeDossier.DieetPeriod}</strong>
                </div>
            </div>
        </React.Fragment>
    );
}