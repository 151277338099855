import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Form, {
  SimpleItem,
  Label,
  ButtonItem,
  ButtonOptions,
  StringLengthRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';

import { sendWeeklyProgressForm } from '../../api/intakeForm';

export default function WeeklyProgressForm() {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const personalFileOid = searchParams.get('personalFileOid');
  const weekNumber = searchParams.get('weekNumber');
  const [weeklyProgressFormFormData, setWeeklyProgressFormFormData] = useState({});
  const editorOptions = { height: 150 };

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await sendWeeklyProgressForm(weeklyProgressFormFormData, personalFileOid, weekNumber).then((result) => {
            if (result.status === 201) {
                notify("Formulier is opgeslagen", 'success', 2000);
                setLoading(false);
            }

        }).catch(error => {
            notify(error.response.data, 'error', 2000)
        });
            setLoading(false);
    };

    const formDataChanged = (e) => {       
        setWeeklyProgressFormFormData({ ...weeklyProgressFormFormData, [e.dataField]: e.value });
        console.log(weeklyProgressFormFormData);
        console.log(e);
    }

  return (
    <form onSubmit={onSubmit}>
          <Form formData={weeklyProgressFormFormData} onFieldDataChanged={formDataChanged} disabled={loading}>
              <SimpleItem editorOptions={editorOptions} editorType="dxTextArea" dataField="Question1_1" >
                  <Label text="Hoe is het afgelopen week gegaan met het eetpatroon?" />
                  <StringLengthRule message="Maximum 500 characters!" max={500} />
              </SimpleItem>
              <SimpleItem editorOptions={editorOptions} editorType="dxTextArea" dataField="Question1_2" >
                  <Label text="Beweging - Hoeveel heb je afgelopen week bewogen en gesport?" />
                  <StringLengthRule message="Maximum 500 characters!" max={500} />
              </SimpleItem>
              <SimpleItem editorOptions={editorOptions} editorType="dxTextArea" dataField="Question1_3" >
                  <Label text="Slaap - Hoe heb je geslapen?" />
                  <StringLengthRule message="Maximum 500 characters!" max={500} />
              </SimpleItem>
              <SimpleItem editorOptions={editorOptions} editorType="dxTextArea" dataField="Question1_4" >
                  <Label text="Ontspanning - Op wat voor manieren heb je tijd voor jezelf genomen?" />
                  <StringLengthRule message="Maximum 500 characters!" max={500} />
              </SimpleItem>
              <SimpleItem editorOptions={editorOptions} editorType="dxTextArea" dataField="Question1_5" >
                  <Label text="Stoelgang - Heb je veranderingen gemerkt in je stoelgang? Zo ja, wat is er veranderd?" />
                  <StringLengthRule message="Maximum 500 characters!" max={500} />
              </SimpleItem>
              <SimpleItem editorOptions={editorOptions} editorType="dxTextArea" dataField="Question1_6" >
                  <Label text="Welk cijfer geef je afgelopen week in het algemeen?" />
                  <StringLengthRule message="Maximum 500 characters!" max={500} />
              </SimpleItem>
              <SimpleItem editorOptions={editorOptions} editorType="dxTextArea" dataField="Question1_7" >
                  <Label text="Welke punten ga je aankomende week verbeteren?" />
                  <StringLengthRule message="Maximum 500 characters!" max={500} />
              </SimpleItem>

        <ButtonItem>
          <ButtonOptions
            width={'25%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Send'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}