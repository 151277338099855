import React, { useEffect, useState } from 'react';
import './activityList.scss';
import DataGrid, {
    Column, Selection, Sorting, HeaderFilter, DataGridTypes,
    RequiredRule, Paging, Pager, Editing, Scrolling, LoadPanel, Lookup, SearchPanel,
    Form, Popup, Item
} from 'devextreme-react/data-grid';
import { TaskPriority, TaskStatus } from '../../Enums';
import SelectBox from 'devextreme-react/select-box';
import { formatMessage } from 'devextreme/localization';
export default function ActivityList(prop) {

    const relationData = {
        store: {
            type: 'odata',
            version: 4,
            key: 'Oid',
            keyType: 'Guid',
            url: `${process.env.REACT_APP_URL}/api/odata/Relation`,
            beforeSend: function (e) {
                e.headers = {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`
                };

            },
        },
        select: [
            'Oid',
            'FullName'
        ],
        sort: [
            { getter: "FirstName", desc: false }
        ]
    };

    const statusCellRender = (cellData) => {
        return (
            <div className={`item-field item-${cellData.text.toLowerCase().replace('| ', '').replace(' ', '-')}`}>
                <span>{cellData.text}</span>
            </div>
        );
    };

    const statusEditorRender = (cell) => {
        const onValueChanged = (e) => cell.setValue(e.value);
        const itemRender = (cellData) => {
            if (cellData != null) {
                return (
                    <div className={`item-field item-${cellData.Name.toLowerCase().replace('| ', '').replace(' ', '-')}`}>
                        <span>{cellData.Name}</span>
                    </div>
                );
            }
            return <span>(All)</span>;
        };
        return (
            <SelectBox
                defaultValue={cell.value}
                {...cell.column.lookup}
                onValueChanged={onValueChanged}
                itemRender={itemRender}
            />
        );
    };

    const priorityEditorRender = (cell) => {
        const onValueChanged = (e) => cell.setValue(e.value);
        const itemRender = (cellData) => {
            if (cellData != null) {
                return (
                    <div className={`item-field item-${cellData.Name.toLowerCase().replace('| ', '').replace(' ', '-')}`}>
                        <span>{cellData.Name}</span>
                    </div>
                );
            }
            return <span>(All)</span>;
        };
        return (
            <SelectBox
                defaultValue={cell.value}
                {...cell.column.lookup}
                onValueChanged={onValueChanged}
                itemRender={itemRender}
            />
        );
    };

    const priorityCellRender = (cellData) => {
        return (
            <div className={`item-field item-${cellData.text.toLowerCase().replace('| ', '').replace(' ', '-')}`}>
                <span>{cellData.text}</span>
            </div>
        );
    };

    return (
        <div>
            <DataGrid
                className='planning-grid theme-dependent'
                dataSource={prop.dataSource}
                hoverStateEnabled
                showBorders
                height='100%'>
                <LoadPanel enabled={false} />
                <Scrolling mode='virtual' />
                <Paging defaultPageSize={50} />
                <Pager visible showPageSizeSelector />
                <Editing mode={prop.mode}
                    allowUpdating
                    allowAdding
                    allowDeleting >
                    <Popup title={formatMessage('Task')} showTitle={true} width={700} height={625} />
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2}>
                            <Item dataField="StartDate" />
                            <Item dataField="DeadLine" />
                            <Item dataField="Priority" />
                            <Item dataField="Status" />
                            <Item
                                dataField="Text"
                                editorType="dxTextArea"
                                editorOptions={{ height: 200 }}
                                colSpan={2}
                                 />
                        </Item>
                    </Form>
                    </Editing>
                <HeaderFilter visible />
                <Sorting mode='multiple' />
                <Column dataField='StartDate' caption={formatMessage('StartPeriod')} width={135} dataType='date'>
                    <RequiredRule />
                </Column>
                <Column dataField='Text' caption={formatMessage('Task')}>
                    <RequiredRule />
                </Column>
                <Column visible={prop.displayRelation} dataField='Relation.Oid' width={200} caption={formatMessage('Relation')}>
                    <Lookup searchExpr="FullName" searchEnabled={true} dataSource={relationData} displayExpr="FullName" valueExpr="Oid" />
                </Column>
                <Column dataField='Status' caption='Status' width={135} editCellRender={statusEditorRender} sortOrder='asc' cellRender={statusCellRender}>
                    <Lookup dataSource={TaskStatus} displayExpr={'Name'} valueExpr={'Value'} />
                    <RequiredRule />
                </Column>
                <Column dataField='Priority' caption='Priority' width={135} caption={formatMessage('Priority')} sortOrder='desc' editCellRender={priorityEditorRender} cellRender={priorityCellRender}>
                    <Lookup dataSource={TaskPriority} displayExpr={'Name'} valueExpr={'Value'} />
                    <RequiredRule />
                </Column>
                <Column dataField='DeadLine' caption={formatMessage('DeadLine')} dataType='date' width={135} sortOrder='asc'>
                    <RequiredRule />
                </Column>
            </DataGrid>
        </div>
    );
};


