import React, { useState, useEffect } from 'react';
import { Chart, Series, ArgumentAxis, Export, Legend, Margin, Tooltip, Grid, Label } from 'devextreme-react/chart';
import { getProgressItems } from '../../api/progressItems';
import { formatMessage } from 'devextreme/localization';
import notify from 'devextreme/ui/notify';
export default function WeightChart(props) {
    const [weightData, setWeightData] = useState({});
    useEffect(() => {
        const getFormData = () => {
            getProgressItems(props.personalFileOid._value).then((result) => {

                setWeightData(result.data.value);
            }).catch(error => notify(error.message, 'error', 2000));
        }
        if (props.parentIsLoaded)
        {
            getFormData();
        }
    }, [props]);

    return (
        <React.Fragment>
            <div className={'content-block'}>
                <Chart
                    dataSource={weightData}
                    palette="Violet">
                    <Margin bottom={20} />
                    <ArgumentAxis
                        valueMarginsEnabled={false}
                        discreteAxisDivisionMode="crossLabels">
                        <Grid visible={true} />
                    </ArgumentAxis>
                    <Series
                        name={formatMessage('Weight')}
                        valueField="Weight"
                        argumentField="MeasurementDate"
                        type="line"
                        color="#79cac4">
                    <Label name='Weight' visible={true} backgroundColor="#c18e92" />
                    </Series>
                    <Series
                        name={formatMessage('Bodyfat')}
                        valueField="Bodyfat"
                        argumentField="MeasurementDate"
                        type="line"
                        color="purple">
                        <Label name='Weight' visible={true} backgroundColor="#c18e92" />
                    </Series>
                    <Legend
                        verticalAlignment="bottom"
                        horizontalAlignment="center"
                        itemTextPosition="bottom" />
                    <Export enabled={true} />
                    <Tooltip enabled={true} />
                </Chart>
            </div>
        </React.Fragment>
    );
}

