import React, { useState, useEffect, useCallback } from 'react';
import Form, {
    SimpleItem,
    ButtonItem,
    ButtonOptions,
    StringLengthRule,
    Label,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { saveIntakeFormRequest, getIntakeFormRequest, updateIntakeFormRequest, getIntakeFormDocument } from '../../api/intakeForm';
export default function Profile(props) {
    const [intakeFormData, setIntakeFormData] = useState({});

    useEffect(() => {
        const getFormData = () => {
            getIntakeFormRequest(props.relationOid).then((result) => {
                setIntakeFormData(prevState => {
                    return { ...prevState, ...result.data.value[0] };

                });
            }).catch(error => notify(error.message, 'error', 2000));
        }
        getFormData();
    }, [props.relationOid]);
    const onSubmit = async (e)  => {
        e.preventDefault();
        if (intakeFormData.Oid == null) {
            await saveIntakeFormRequest(intakeFormData, props.relationOid).then((result) => {
                if (result.status === 201) {
                    setIntakeFormData(prevState => {
                        return { ...prevState, ...result.data };

                    });
                    notify("Formulier is opgeslagen", 'success', 2000);
                }

            }).catch(error => notify(error.message, 'error', 2000));
        }
        else {
            await updateIntakeFormRequest(intakeFormData).then((result) => {
                if (result.status === 204) {
                    notify("Formulier is gewijzigd", 'success', 2000);
                }
            }).catch(error => notify(error.message, 'error', 2000));
        }

    };
    const formDataChanged = (e) => {
        setIntakeFormData({ ...intakeFormData, [e.dataField]: e.value });
    }

    const downloadPdf = useCallback((e) => {
        getIntakeFormDocument(intakeFormData.Oid).then((res) => {
            const file = new Blob(
                [res.data],
                { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            var fileName = res.headers['content-disposition'].replace("inline; filename=","");
            fileLink.download = fileName;
            fileLink.click();
            window.open(fileLink.href);
        }).catch((e) => {
            if (intakeFormData.Oid == null) { notify("No intake form found! Save first", 'error', 2000); return}
            notify(e.message, 'error', 2000);
        });
    }, [intakeFormData.Oid]);

    return (
        <React.Fragment>
            <div className={'content-block'}>
                <form onSubmit={onSubmit}>
                    <Form formData={intakeFormData} onFieldDataChanged={formDataChanged} labelLocation="top" >
                        <SimpleItem >
                            <h6>Diëtistisch onderzoek</h6>
                        </SimpleItem>
                    <SimpleItem editorType="dxTextArea" dataField="Question1_1" >
                            <Label text="Hulpvraag/ gezondheidsprobleem ‘Waarom ben je naar CYD gekomen?’" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                    </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question1_2" >
                        <Label text="Dieetgeschiedenis" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question1_3" >
                        <Label text="Medische geschiedenis" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question1_4" >
                        <Label text="Leefvorm/woonsituatie" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question1_5" >
                        <Label text="Opleiding/werk" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question1_6" >
                        <Label text="Antropometrie" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>

                        <SimpleItem >
                            <h6>Gezondheidsvragen</h6>
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question2_1" >
                            <Label text="Voedingsgewoonten" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question2_2" >
                            <Label text="Allergieën" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question2_3" >
                            <Label text="Voedingssupplementen" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question2_4" >
                            <Label text="Glazen alcohol p.w./weekend " />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question2_5" >
                            <Label text="Vochtinname" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question2_6" >
                            <Label text="Stoelgang" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question2_7" >
                            <Label text="Roken" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question2_8" >
                            <Label text="Lichamelijke activiteiten" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question2_9" >
                            <Label text="Ervaren van stress" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question2_10" >
                            <Label text="Slaap" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question2_11" >
                            <Label text="Geloofsovertuiging (ivm voeding)" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question2_12" >
                            <Label text="Motivatie cliënt (Beoordelen met cijfer)" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>


                        <SimpleItem >
                            <h6>Voedingspatroon</h6>
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question3_1" >
                            <Label text="Ontbijt" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question3_2" >
                            <Label text="Tussendoor" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question3_3" >
                            <Label text="Lunch" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question3_4" >
                            <Label text="Tussendoor 1" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question3_5" >
                            <Label text="Warme maaltijd" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question3_6" >
                            <Label text="Tussendoor 2 " />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>
                        <SimpleItem editorType="dxTextArea" dataField="Question3_6" >
                            <Label text="Tussendoor 2 " />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                        </SimpleItem>

                    <ButtonItem>
                        <ButtonOptions
                            width={'20%'}
                            type={'default'}
                            useSubmitBehavior={true}>
                            <span className="dx-button-text">
                                Save
                            </span>
                        </ButtonOptions>
                        </ButtonItem>
                        <ButtonItem>
                            <ButtonOptions
                                width={'20%'}
                                type={'default'}
                                onClick={downloadPdf}>
                                <span className="dx-button-text">
                                    Download PDF
                            </span>
                            </ButtonOptions>
                        </ButtonItem>
                    </Form>

                </form>
            </div>
         
        </React.Fragment>
    );
}
