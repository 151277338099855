import React, { useState, useEffect, useCallback } from 'react';
import Form, {
    SimpleItem,
    ButtonItem,
    ButtonOptions,
    StringLengthRule,
    Label,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { saveCompanyFormRequest } from '../../api/company';
export default function CompanyForm({ onComplete }) {
    const [companyFormData, setCompanyFormData] = useState({});

    useEffect(() => {
    }, []);

    const onSubmit = async (e)  => {
        e.preventDefault();
        await saveCompanyFormRequest(companyFormData).then((result) => {
                if (result.status === 201) {
                    setCompanyFormData({});
                    onComplete();
                    notify("Formulier is opgeslagen", 'success', 2000);
                }

            }).catch(error => notify(error.message, 'error', 2000));
    };
    const formDataChanged = (e) => {
        setCompanyFormData({ ...companyFormData, [e.dataField]: e.value });
    }

    return (
        <React.Fragment>
            <div className={'content-block'}>
                <form onSubmit={onSubmit}>
                    <Form formData={companyFormData} onFieldDataChanged={formDataChanged} labelLocation="top" >
                    <SimpleItem editorType="dxTextBox" dataField="Name" >
                            <Label text="Company name" />
                            <StringLengthRule message="Maximum 500 characters!" max={500} />
                    </SimpleItem>
                    <ButtonItem>
                        <ButtonOptions
                            width={'20%'}
                            type={'default'}
                            useSubmitBehavior={true}>
                            <span className="dx-button-text">
                                Save
                            </span>
                        </ButtonOptions>
                        </ButtonItem>
                    </Form>
                </form>
            </div>
         
        </React.Fragment>
    );
}
