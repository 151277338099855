import axios from 'axios';
export async function getProgressItems(personalFileOid) {
    const res = await axios.get(`/api/odata/ProgressItem?$filter=PersonalFileOid eq '${personalFileOid}'&$orderby=MeasurementDate asc`, await GetHeader());
    try {
        return res;
    }
    catch {
        return res;
    }
}

async function GetHeader() {
    const header = {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`
        }
    }
    return header;
}
