import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';

import './LoginForm.scss';

export default function LoginForm() {
    const { signIn } = useAuth();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(loadFormData());
    useEffect(() => {

    }, []);
  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
      setLoading(true);
      const result = await signIn(formData.userName, formData.password);
    if (!result.isOk) {
      setLoading(false);
      notify(result.message, 'error', 2000);
    }
  }, [signIn, formData]);

    const onFieldDataChanged = (e) => {
        switch (e.dataField) {
            case "rememberMe":
                if (e.value) {
                    localStorage.setItem('authObject', JSON.stringify(formData));
                }
                else {
                    localStorage.removeItem('authObject');
                }
                break;
            case "userName":
                setFormData(prevState => {
                    return { ...prevState, userName: e.value }
                });
                if (formData.rememberMe) { localStorage.setItem('authObject', JSON.stringify(formData)); }
                break;
            case "password":
                var value = "";
                if (e.value !== "") { value = e.value; }
                setFormData(prevState => {
                    return { ...prevState, password: e.value }
                });
                if (formData.rememberMe) { localStorage.setItem('authObject', JSON.stringify(formData)); }
                break;
        }
    }
    function loadFormData()
    {
        if (localStorage.hasOwnProperty("authObject")) {
            return JSON.parse(localStorage.getItem('authObject'))
        }
        else
        {
            return { userName: '', password: '', rememberMe: false }
        }
    }

  return (
    <form className={'login-form'} onSubmit={onSubmit}>
          <Form formData={formData} onFieldDataChanged={onFieldDataChanged} disabled={loading}>
        <Item
          dataField={'userName'}
          editorType={'dxTextBox'}
          editorOptions={emailEditorOptions}>
          <RequiredRule message="Email is required" />
         
          <Label visible={false} />
        </Item>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}>
          <Label visible={false} />
        </Item>
        <Item
          dataField={'rememberMe'}
          editorType={'dxCheckBox'}
          editorOptions={rememberMeEditorOptions} >
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}>
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Sign In'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={'link'}>
            <Link to={'/reset-password'}>Forgot password?</Link>
          </div>
              </Item>
      </Form>
    </form>
  );
}

const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Email', mode: 'text' };
const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };
const rememberMeEditorOptions = { text: 'Remember me', elementAttr: { class: 'form-text' } };
